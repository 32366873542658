<script setup lang="ts">
import useGetInstitutionSiteDetail from '@core/queries/institutionSite/getInstitutionSiteDetail';
import { INSTITUTION_SITE_MAP } from '@/data/urls';
import snippets from '@/utils/snippets';

import { goTo } from '@/utils/url';
import ReviewList from '@/components/institution-site-detail/components/ReviewList.vue';
import Map from '@/components/Map.vue';
import Button from '@/components/Button.vue';
import InstitutionSiteDatalist from '@/components/institution-site-detail/components/InstitutionSiteDatalist.vue';
import Loader from '@/components/Loader.vue';

const props = defineProps({
    id: {
        type: Number,
        required: true,
    },
});

const { institutionSite, isPendingGetInstitutionSite, isSuccessGetInsitutionSite } = useGetInstitutionSiteDetail(props.id);

function goToMap(lat: number, long: number) {
    goTo(INSTITUTION_SITE_MAP, [lat, long], true);
}
</script>

<template>
    <div class="layout bound-x bg-white">
        <div v-if="isPendingGetInstitutionSite" class="p-6 flex justify-center">
            <Loader />
        </div>
        <div v-if="isSuccessGetInsitutionSite" class="flex flex-col-reverse vp9:grid vp7:grid-cols-3 gap-12 vp9:gap-6 vp12:gap-12">
            <div class="col-span-2">
                <ReviewList :institution-site="institutionSite!" />
            </div>
            <div class="vp9:col-span-1">
                <div class="vp9:p-4 vp12:p-6 rounded-[20px] vp9:border border-grey">
                    <div v-if="institutionSite!.lat !== 0 && institutionSite!.long !== 0" class="hidden vp9:block relative mb-6">
                        <Map
                            class="c-map--square"
                            :lat="institutionSite!.lat"
                            :lng="institutionSite!.long"
                        />
                        <Button
                            :text="snippets.get('view_on_map')"
                            class="z-[0] c-button--small typo-small absolute bottom-3 left-3"
                            @click="goToMap(institutionSite!.lat, institutionSite!.long)"
                        />
                    </div>
                    <InstitutionSiteDatalist
                        :institution-site="institutionSite!"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
