<script setup lang="ts">
import Overlay from './Overlay.vue';
import Window from './Window.vue';
import Button from './Button.vue';
import snippets from '@/utils/snippets';

const props = defineProps({
    title: {
        type: String,
    },
    text: {
        type: String,
    },
    confirmButtonText: {
        type: String,
        default: snippets.get('confirm'),
    },
    confirmButtonDisabled: {
        type: Boolean,
        default: false,
    },
    cancelButtonText: {
        type: String,
        default: snippets.get('cancel'),
    },
});

const emits = defineEmits(['cancel', 'confirm']);

function onAction(action) {
    if (action.id === 'close')
        cancel();
}

function cancel() {
    emits('cancel');
}

function confirm() {
    emits('confirm');
}
</script>

<template>
    <Overlay :auto-close="false">
        <Window
            :title="props.title"
            :has-scrollable-content="false"
            class="c-window--compact"
            @action="onAction"
        >
            {{ props.text }}
            <slot />
            <template #footer>
                <div class="flex gap-4">
                    <Button
                        :text="props.cancelButtonText"
                        type="button"
                        class="c-button--secondary c-button--outline"
                        @click="cancel"
                    />
                    <Button
                        type="button"
                        :disabled="props.confirmButtonDisabled"
                        :text="props.confirmButtonText"
                        class="c-button--secondary"
                        @click="confirm"
                    />
                </div>
            </template>
        </Window>
    </Overlay>
</template>
