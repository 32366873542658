<script setup lang="ts">
import { injectPloc } from '@core';
import Error from '@/components/Error.vue';
import Button from '@/components/Button.vue';
import { COMPARISON_PLOC } from '@/utils';
import snippets from '@/utils/snippets';

const comparisonPloc = injectPloc(COMPARISON_PLOC);
</script>

<template>
    <div>
        <Error
            :title="snippets.get('comparison_placeholder_title')"
            :text="snippets.get('comparison_placeholder_description')"
        >
            <Button
                :text="snippets.get('comparison_placeholder_button_title')"
                @click="e => comparisonPloc.enableMakingNewComparison()"
            />
        </Error>
    </div>
</template>
