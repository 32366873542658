import type { MatomoEvent } from '@/types/matomoEvent';

export function pushToMatomo(event: string, formId: string, stepName: string, skipQuestion?: boolean) {
    window._mtm = window._mtm || [];
    const eventData: MatomoEvent = {
        event,
        formID: formId,
        stepName,
    };

    // Check if skipQuestion is provided
    if (skipQuestion !== undefined)
        eventData.skipQuestion = skipQuestion ? 'Vraag overgeslagen' : 'Vraag ingevuld';

    // Push the event to Matomo
    window._mtm.push({ ...eventData });
}
