<script setup lang="ts">
import { defineProps } from 'vue';

import type { SelectOption } from '@core/common/types';
import { useModelWrapper } from '@/utils/modelWrapper';

interface Props {
    id: string
    label?: string
    modelValue: string | number
    options: SelectOption[]
}

const props = defineProps<Props>();

const emit = defineEmits(['update:modelValue']);

const selectedValue = useModelWrapper(props, emit);
</script>

<template>
    <div class="c-input-group c-radio-buttons">
        <label v-if="label" :for="id" class="c-label">{{ label }}</label>
        <div v-for="option in options" :key="option.value" class="c-radio-buttons__item">
            <input :id="option.value.toString()" v-model="selectedValue" class="c-radio-buttons__item-input" type="radio" :name="id" :value="option.value">
            <label class="c-radio-buttons__item-label" :for="option.value.toString()">
                {{ option.displayValue ?? option.value }}
            </label>
        </div>
    </div>
</template>
