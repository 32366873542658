<script setup lang="ts">
import { defineProps } from 'vue';

import Icon from '@/components/Icon.vue';
import { useModelWrapper } from '@/utils/modelWrapper';

interface Props {
    id: string
    label?: string
    modelValue: string
    placeholder?: string
}

const props = defineProps<Props>();

const emit = defineEmits(['update:modelValue']);

const selectedValue = useModelWrapper(props, emit);
</script>

<template>
    <div class="c-input-group">
        <label v-if="label" :for="id" class="c-label">{{ label }}</label>
        <div class="c-select-group">
            <select :id="id" v-model="selectedValue" class="c-select">
                <option v-if="placeholder" value="" disabled class="c-select__option">
                    {{ placeholder }}
                </option>
                <slot />
            </select>
            <Icon name="chevron-down" class="c-select__icon" />
        </div>
    </div>
</template>
