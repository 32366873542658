<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import getReviewForEdit from '@core/queries/review/getReviewForEdit';
import type { Nullable } from '@core/common/types';
import { injectPloc } from '@core';
import usePublishReview from '@core/mutations/public/review/publishReview';
import { CREATE_REVIEW_PLOC } from '@/utils';
import CreateReview from '@/components/create-review/CreateReview.vue';
import PublishReviewConfirmation from '@/components/create-review/PublishReviewConfirmation.vue';
import Loader from '@/components/Loader.vue';
import { ReviewActions } from '@/components/create-review/types/ReviewActions';

defineProps({
    institutionsSiteComplaintUrl: {
        type: String,
    },
});

const createReviewPloc = injectPloc(CREATE_REVIEW_PLOC)!;

const selectedReviewId = ref<Nullable<number>>(null);
const selectedToken = ref<Nullable<string>>(null);
const selectedAction = ref<Nullable<string>>(ReviewActions.Create);

const { reviewForEdit, isSuccessReviewForEdit, isPendingReviewForEdit, isLoadingReviewForEdit } = getReviewForEdit(selectedReviewId, selectedAction, selectedToken);
const { publishReview, isPublishingReview, isSuccessPublishReview } = usePublishReview();

onMounted(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const encodedToken = urlParams.get('token');
    if (encodedToken) {
        const { reviewId, editToken, action } = JSON.parse(atob(encodedToken!));
        selectedReviewId.value = Number.parseInt(reviewId);
        selectedToken.value = editToken;
        selectedAction.value = action;

        if (selectedAction.value === ReviewActions.Publish) {
            publishReview({
                reviewId: selectedReviewId.value,
                token: selectedToken.value!,
            });
        }
    }
});

watch(isPendingReviewForEdit, () => {
    if (isSuccessReviewForEdit.value)
        createReviewPloc.setReviewStateForUpdate(reviewForEdit.value!, selectedToken.value!);
    else
        window.location.href = '/';
});
</script>

<template>
    <div v-if="isPublishingReview || isLoadingReviewForEdit" class="p-6 flex justify-center col-span-2">
        <Loader />
    </div>
    <CreateReview v-if="selectedAction === ReviewActions.Create || isSuccessReviewForEdit" :institutions-site-complaint-url="institutionsSiteComplaintUrl" />
    <PublishReviewConfirmation v-if="isSuccessPublishReview" />
</template>
