<script setup lang="ts">
import { defineProps, ref } from 'vue';
import type { GroupedOptions } from '@core/common/types/GroupedOptions';
import BaseSelect from '@/components/composition/BaseSelect.vue';

const props = defineProps<{
    groupedOptions: GroupedOptions[]
    id: string
}>();

const selectedValue = ref('');
</script>

<template>
    <BaseSelect :id="id" v-model="selectedValue" :bind="props">
        <template #default>
            <optgroup v-for="{ groupLabel, options } in groupedOptions" :key="groupLabel" :label="groupLabel">
                <option v-for="option in options" :key="option" :value="option" class="c-select__option">
                    {{ option }}
                </option>
            </optgroup>
        </template>
    </BaseSelect>
</template>
