<script setup lang="ts">
import { injectPloc } from '@core';
import { SEARCH_PLOC } from '@/utils';

const props = defineProps({
    prefix: {
        type: String,
    },
    suffix: {
        type: String,
        default: 'organisaties',
    },
});

const searchPloc = injectPloc(SEARCH_PLOC);
</script>

<template>
    {{ props.prefix }} <strong class="text-black">{{ searchPloc.state.pagination.totalAmount }}</strong> {{ props.suffix }}
</template>
