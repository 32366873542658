<script setup lang="ts">
import { computed } from 'vue';
import { injectPloc } from '@core';
import Reset from '../../Reset.vue';
import { SEARCH_PLOC } from '@/utils';
import { stateToUrl } from '@/components/institution-site-search/utils/url-state';

const searchPloc = injectPloc(SEARCH_PLOC);

const activeFiltersCount = computed(() => {
    return searchPloc.getActiveFiltersCount();
});

const hasFilters = computed(() => {
    return activeFiltersCount.value > 0;
});

function clearFilters() {
    searchPloc.clearFilters();
    stateToUrl(searchPloc);
}
</script>

<template>
    <div class="flex items-center justify-between relative">
        <h2 class="typo-h6">
            {{ hasFilters
                ? `${activeFiltersCount} ${activeFiltersCount > 1 ? 'filters' : 'filter'} actief`
                : 'Filters'
            }}
        </h2>
        <div v-if="hasFilters" class="absolute right-0">
            <Reset @click="clearFilters" />
        </div>
    </div>
</template>
