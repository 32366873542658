import { useMutation } from '@tanstack/vue-query';
import { reviewCommentApi } from '../../../api';

import { NotificationSeverity } from '../../../features';
import { dependenciesLocator } from '../../../common';
import type { UpdateReviewCommentRequest } from '../../../api/review-comment/requests/UpdateReviewCommentRequest';

const notificationPloc = dependenciesLocator.provideNotificationPloc();

function useUpdateReviewComment() {
    const { mutate, isPending, isSuccess } = useMutation({
        mutationFn: (request: UpdateReviewCommentRequest) => reviewCommentApi.updateReviewComment(request),
        onError(error) {
            notificationPloc.notify({
                title: 'API Error',
                text: error.message,
                severity: NotificationSeverity.Error,
            });
        },
        retry: false,
    });

    return {
        updateReviewComment: mutate,
        isUpdatingReviewComment: isPending,
        isSuccessUpdatingReviewComment: isSuccess,
    };
}

export default useUpdateReviewComment;
