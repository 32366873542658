<script setup lang="ts">
import { computed } from 'vue';

import Icon from '@/components/Icon.vue';

const props = defineProps({
    recordsPerPage: {
        type: Number,
        default: 10,
    },
    recordsCount: {
        type: Number,
        default: 0,
    },
    visibleButtons: {
        type: Number,
        default: 4,
    },
});

const currentPage = defineModel<number>({ default: 1 });

const skip = computed({
    get() {
        return (currentPage.value - 1) * props.recordsPerPage;
    },
    set(skip: number) {
        setPage(skip / props.recordsPerPage + 1);
    },
});

const firstPage = 1;
const totalPagesCount = computed(() => Math.ceil(props.recordsCount / props.recordsPerPage));
const middlePages = computed(() => {
    return '0'
        .repeat(totalPagesCount.value - 2)
        .split('')
        .map((_, idx) => idx + 2)
        .filter(x => Math.abs(currentPage.value - x) < props.visibleButtons);
});

function setPage(page: number) {
    currentPage.value = page;
}

function skipPages(pagesToSkip: number) {
    skip.value = pagesToSkip;
}

function recordsToSkip(page: number) {
    return (page - 1) * props.recordsPerPage;
}

function isVisiblePage(page: number) {
    // Make wantedButtons always uneven and at least 5
    let wantedButtons = props.visibleButtons % 2 === 1 ? props.visibleButtons : props.visibleButtons + 1;
    wantedButtons = Math.max(5, wantedButtons);
    // Early return if we have enough space for all the pages
    if (totalPagesCount.value <= wantedButtons)
        return true;
    // Calculate thresholds
    const magicNr1 = Math.ceil(wantedButtons / 2);
    const magicNr2 = wantedButtons - 2;
    // When we are situated on the left of the pager
    if (currentPage.value <= magicNr1)
        return page <= magicNr2;
    // When we are situated on the right of the pager
    if (currentPage.value > totalPagesCount.value - magicNr1)
        return page > totalPagesCount.value - magicNr2;
    // When we are situated in the center
    return Math.abs(currentPage.value - page) <= (wantedButtons - 5) / 2;
}
</script>

<template>
    <div v-if="props.recordsPerPage < props.recordsCount" class="flex items-center justify-center">
        <div class="c-paginator">
            <div class="c-paginator__pages">
                <button
                    class="c-paginator__item"
                    :class="{ 'c-paginator__item--disabled': currentPage <= 1 }"
                    title="Ga naar vorige pagina"
                    @click="currentPage > 1 ? skipPages(skip - props.recordsPerPage) : null"
                >
                    <Icon name="chevron-left" />
                </button>
                <button class="c-paginator__item" :class="{ 'c-paginator__item--active': skip === 0 }" title="Ga naar pagina 1" @click="skipPages(recordsToSkip(firstPage))">
                    {{ firstPage }}
                </button>
                <span v-if="!isVisiblePage(2)" class="c-paginator__divider">...</span>
                <button
                    v-for="page in middlePages"
                    v-show="isVisiblePage(page)"
                    :key="page"
                    class="c-paginator__item"
                    :class="{ 'c-paginator__item--active': skip === recordsToSkip(page) }"
                    :title="`Ga naar pagina ${page}`"
                    @click="skipPages(recordsToSkip(page))"
                >
                    {{ page }}
                </button>
                <span v-if="!isVisiblePage(totalPagesCount - 1)" class="c-paginator__divider">...</span>
                <button
                    class="c-paginator__item"
                    :class="{ 'c-paginator__item--active': skip === recordsToSkip(totalPagesCount) }" :title="`Ga naar pagina ${totalPagesCount}`"
                    @click="skipPages(recordsToSkip(totalPagesCount))"
                >
                    {{ totalPagesCount }}
                </button>
                <button
                    class="c-paginator__item"
                    :class="{ 'c-paginator__item--disabled': skip === recordsToSkip(totalPagesCount) }"
                    title="Ga naar volgende pagina"
                    @click="skip !== recordsToSkip(totalPagesCount) ? skipPages(skip + props.recordsPerPage) : null"
                >
                    <Icon name="chevron-left" class="c-icon--flip" />
                </button>
            </div>
        </div>
    </div>
</template>
