<script setup lang="ts">
import Overlay from '@/components/Overlay.vue';
import Window from '@/components/Window.vue';
import snippets from '@/utils/snippets';

const showDialog = defineModel();
</script>

<template>
    <Overlay v-if="showDialog" @close="showDialog = false">
        <Window class="w-fit" :title="snippets.get('create_review_accept_guidelines_title')" @action="showDialog = false">
            <div class="flow-md" style="max-width: 900px">
                <section id="faq-1" class="b-scroll-nav__content-item flex flex-col gap-4 scroll-mt-4">
                    <h2 class="typo-h6">
                        Algemeen
                    </h2>
                    <article class="c-card bg-light-grey">
                        <p>Vul steeds je <b>eigen persoonlijke gegevens</b> in en maak gebruik van een <b>geldig e-mailadres</b>.</p>
                    </article>
                </section>
                <section id="faq-2" class="b-scroll-nav__content-item flex flex-col gap-4 scroll-mt-4">
                    <h2 class="typo-h6">
                        Timing van de waardering
                    </h2>
                    <article class="c-card bg-light-grey">
                        <p>Schrijf voor eenzelfde organisatie maximaal <b>één waardering per ervaring</b>.</p>
                    </article>
                    <article class="c-card bg-light-grey">
                        <p>Schrijf een waardering die een <b>ervaring van maximaal 6 maanden</b> geleden weerspiegelt.</p>
                    </article>
                </section>
                <section id="faq-3" class="b-scroll-nav__content-item flex flex-col gap-4 scroll-mt-4">
                    <h2 class="typo-h6">
                        Inhoud van de waardering
                    </h2>
                    <article class="c-card bg-light-grey">
                        <p>
                            Vermeld <b>geen persoonsgegevens</b>, behoudens in uitzonderlijke gevallen (bv. in geval van een waardering van een individuele zorgaanbieder waarvan de naam van de organisatie gelijk is aan de persoonsnaam,
                            zoals een onthaalouder). Meer concreet bedoelen we hiermee:
                            <ul class="guidelines-modal-ul">
                                <li>Vermeld geen namen van zorgverleners of begeleiders binnen een organisatie en ook <b>eigen persoonsgegevens</b> of die van <b>derden</b> zoals naam, e-mailadres of telefoonnummer mogen <b>niet gedeeld</b> worden op dit platform.</li>
                                <li>Vermeld geen gegevens die gelinkt kunnen worden aan een <b>uniek persoon</b> (bv. 'de verpleger met een rode bril', 'de directeur', 'de kale man',...)</li>
                                <li>Vermeld zeker <b>geen gevoelige persoonsgegevens</b> zoals medische aandoeningen of andere gezondheidsgegevens.</li>
                            </ul>
                        </p>
                    </article>
                    <article class="c-card bg-light-grey">
                        <p>Plaats enkel een waardering over jouw <b>eigen ervaringen</b>, of in naam van een persoon uit jouw nabije omgeving die jou vraagt een waardering te schrijven in zijn of haar naam. </p>
                    </article>
                    <article class="c-card bg-light-grey">
                        <p>Beschuldig niemand en baseer jouw waardering enkel op effectieve <b>feiten en persoonlijke ervaringen</b> en niet op vermoedens of geruchten.</p>
                    </article>
                    <article class="c-card bg-light-grey">
                        <p>
                            <b>Officiële klachten hebben geen plaats</b> op dit waarderingsplatform. Hiervoor richt je je in de eerste plaats naar de betreffende organisatie.
                            Indien de situatie daar niet kan worden opgelost, contacteer dan de klachtenlijn van de betreffende sector. Daar zal jouw klacht verder behandeld worden.
                        </p>
                    </article>
                    <article class="c-card bg-light-grey">
                        <p>
                            Heb <b>respect</b> voor andere gebruikers en organisaties die je beoordeelt.
                        </p>
                    </article>
                    <article class="c-card bg-light-grey">
                        <p>Gebruik <b>geen enkele vorm van verbaal geweld</b>.</p>
                    </article>
                    <article class="c-card bg-light-grey">
                        <p>Vermeld <b>geen</b> (links naar) <b>andere websites, reclame of spam</b> in jouw waardering.</p>
                    </article>
                </section>
                <section id="faq-4" class="b-scroll-nav__content-item flex flex-col gap-4 scroll-mt-4">
                    <h2 class="typo-h6">
                        Taalgebruik en schrijfstijl
                    </h2>
                    <article class="c-card bg-light-grey">
                        <p>Schrijf enkel <b>Nederlandstalige</b> waarderingen.</p>
                    </article>
                    <article class="c-card bg-light-grey">
                        <p>Maak <b>geen</b> gebruik van <b>scheldwoorden en vulgaire taal</b>.</p>
                    </article>
                    <article class="c-card bg-light-grey">
                        <p>Maak <b>geen</b> onnodig gebruik van <b>hoofdletters, leestekens of emoticons</b>.</p>
                    </article>
                </section>
            </div>
        </Window>
    </Overlay>
</template>

<style>
    .guidelines-modal-ul {
        list-style: disc !important;
        margin-top: 20px;
        margin-left: 20px;
    }
</style>
