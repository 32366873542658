<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { injectPloc } from '@core';
import snippetsUtil from '../../../utils/snippets';
import TreeFilter from '@/components/institution-site-search/filters/TreeFilter.vue';
import Collapsible from '@/components/Collapsible.vue';
import { SEARCH_PLOC } from '@/utils';
import { stateToUrl } from '@/components/institution-site-search/utils/url-state';

const searchPloc = injectPloc(SEARCH_PLOC);

const values = computed({
    get() {
        return searchPloc.state.selectedSectorFilters;
    },
    set(value) {
        searchPloc.setSelectedSectorFilters(value);
        stateToUrl(searchPloc);
    },
});
onMounted(async () => {
    searchPloc.loadSectors();
});
</script>

<template>
    <Collapsible :title="snippetsUtil.get('search_type')" :badge="searchPloc.state.selectedTypes.length">
        <TreeFilter
            v-model:values="values"
            :options="searchPloc.state.sectorFilters"
        />
    </Collapsible>
</template>
