import { useQuery } from '@tanstack/vue-query';

import type { Ref } from 'vue';
import { institutionSiteApi } from '../../api';
import { queryKeys } from '../../common/utils/constants';

export default function getInstitutionSitesForComparison(institutionSiteIds: Ref<string[]>) {
    const { data, isPending, isSuccess } = useQuery({
        enabled: () => institutionSiteIds.value.length > 0,
        queryKey: [queryKeys.public.institutionSite.GET_INSTITUTION_SITES_FOR_COMPARISON, institutionSiteIds],
        queryFn: () => institutionSiteApi.getInstitutionSitesForComparison(institutionSiteIds.value),
        throwOnError: true,
        staleTime: 5000,
        select: (response) => {
            return response!.data;
        },
    });

    return {
        isSuccessComparison: isSuccess,
        isPendingComparison: isPending,
        comparison: data,
    };
}
