import { useQuery } from '@tanstack/vue-query';

import { institutionSiteApi } from '../../api';
import { queryKeys } from '../../common/utils/constants';
import type { InstitutionSiteDetail } from '../../features';

export default function useGetInstitutionSiteDetail(institutionSiteId: number) {
    const { data, isPending, isSuccess } = useQuery({
        enabled: () => !!institutionSiteId,
        queryKey: [queryKeys.public.institutionSite.GET_INSTITUTION_SITE_DETAIL, institutionSiteId],
        queryFn: () => institutionSiteApi.getInstitutionSiteDetailById(institutionSiteId),
        throwOnError: true,
        staleTime: 5000,
        select: (response) => {
            return response!.data as InstitutionSiteDetail;
        },
    });

    return {
        isSuccessGetInsitutionSite: isSuccess,
        isPendingGetInstitutionSite: isPending,
        institutionSite: data,
    };
}
