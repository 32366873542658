<script setup lang="ts">
import { injectPloc } from '@core';
import { computed } from 'vue';
import { reviewAnswerRatings } from '../data/reviewAnswerRatings';
import { CREATE_REVIEW_PLOC } from '@/utils';
import snippets from '@/utils/snippets';
import Button from '@/components/Button.vue';
import { Icon } from '@/components';
import CreateReviewTitle from '@/components/create-review/components/CreateReviewTitle.vue';
import { pushToMatomo } from '@/utils/matomo';

const createReviewPloc = injectPloc(CREATE_REVIEW_PLOC)!;
const minRequiredAnswers = computed(() => createReviewPloc.state.questions.length - createReviewPloc.state.amountOfSkippableQuestions);

function goToNextQuestion(skippedQuestion: boolean, questionText: string) {
    createReviewPloc.goToNextQuestion();
    pushToMatomo('formStep', createReviewPloc.state.institutionSite!.institutionType.name, questionText, skippedQuestion);
}
</script>

<template>
    <div
        v-for="(question, index) in createReviewPloc.state.questions"
        v-show="index === createReviewPloc.state.currentActiveQuestion - 1"
        :key="question.id"
        class="bg-white flex flex-col items-center gap-8 p-8 vp7:rounded-[20px] self-start"
    >
        <CreateReviewTitle
            :title="question.categoryName"
            :subtitle="question.categoryExtraClarificationText"
            :mobile-title="createReviewPloc.state.institutionSite!.name"
        />
        <div class="flex flex-col gap-8 vp9:gap-2 w-full">
            <div class="u-bg-box">
                <label for="radiobuttonrating-1" class="c-label c-label--large block w-full">
                    {{ question.text }}
                </label>

                <div class="c-radio-buttons-rating">
                    <div v-for="rating in reviewAnswerRatings" :key="rating.value" class="c-radio-buttons-rating__item">
                        <input :id="`rate-${rating.value}-${question.id}`" v-model="question.userScore" :disabled="question.isSkipped" class="c-radio-buttons-rating__item-input" type="radio" :name="`rate-${rating.value}-${question.id}`" :value="rating.value">
                        <label class="c-radio-buttons-rating__item-label" :for="`rate-${rating.value}-${question.id}`">
                            <Icon class="c-icon c-radio-buttons-rating__item-icon" :class="`bg-${rating.background}`" :name="`rating-${rating.value}`" />
                            {{ rating.text }}
                        </label>
                    </div>
                </div>
            </div>

            <div class="u-bg-box">
                <label for="message-1" class="c-label c-label--large block w-full">
                    {{ snippets.get('review_question_score_explanation') }}
                    <span class="c-label__optional">
                        {{ snippets.get('optional') }}
                    </span>
                </label>
                <div class="c-input-group">
                    <textarea :id="`message-${question.id}`" v-model="question.userRemark" :disabled="question.isSkipped" class="c-input" :name="`message-${question.id}`" rows="4" :placeholder="snippets.get('create_review_questions_remark_placeholder')" />
                </div>
            </div>
        </div>

        <div v-if="!createReviewPloc.getIsQuestionSkippable() && !question.isSkipped" class="flex flex-wrap gap-4 justify-between w-full">
            <div class="c-alert">
                <Icon class="c-alert--icon c-steps-vertical__icon is-skipped" name="form-progress-skipped-indicator" />
                <div class="c-alert--content">
                    <div class="c-alert--title">
                        {{ snippets.get('create_review_no_skippable_questions_title') }}
                    </div>
                    <div
                        class="c-alert--text" v-html="snippets.get('create_review_no_skippable_questions_description', { minRequiredAnswers: minRequiredAnswers.toString(),
                                                                                                                         totalQuestions: createReviewPloc.state.questions.length.toString(),
                                                                                                                         currentRequiredAnswers: createReviewPloc.state.questions.filter(q => q.userScore).length.toString() })"
                    />
                </div>
            </div>
        </div>

        <div class="flex flex-wrap gap-4 justify-between w-full">
            <div class="c-checkbox-group c-checkbox-group--button" :class="{ 'is-disabled': !createReviewPloc.getIsQuestionSkippable() && !question.isSkipped }">
                <div class="c-checkbox-group__item">
                    <input :id="`skip-${question.id}`" v-model="question.isSkipped" :disabled="!createReviewPloc.getIsQuestionSkippable() && !question.isSkipped" class="c-checkbox-group__input" type="checkbox" :name="`skip-${question.id}`" @input="question.userScore = null">
                    <label class="c-checkbox-group__label" :for="`skip-${question.id}`">
                        <span class="c-checkbox-group__icon" />
                        <span>Vraag overslaan</span>
                        <Icon
                            v-tooltip.top="
                                {
                                    class: 'c-tooltip',
                                    value: snippets.get('tooltip_skip_question', { minRequiredAnswers: minRequiredAnswers.toString(),
                                                                                   totalQuestions: createReviewPloc.state.questions.length.toString() }),
                                    hideDelay: 300,
                                }"
                            class="c-checkbox-group__tooltip c-icon--decorated bg-black text-white shrink-0" name="info"
                        />
                    </label>
                </div>
            </div>

            <div class="w-full vp9:w-auto flex gap-4 justify-between fixed bottom-0 left-0 right-0 px-4 py-6 bg-white border-t border-t-light-grey-2 vp7:relative vp7:p-0 z-50 vp7:border-none">
                <Button
                    v-if="!createReviewPloc.getIsUpdate() || createReviewPloc.state.currentActiveQuestion > 1"
                    class="c-button typo-button c-button--primary c-button--outline min-w-auto"
                    :text="snippets.get('create_review_previous')"
                    @click="createReviewPloc.goToPreviousQuestion()"
                />
                <Button
                    class="c-button typo-button c-button--secondary min-w-auto"
                    :text="snippets.get('create_review_next')"
                    :disabled="!question.userScore && !question.isSkipped"
                    @click="goToNextQuestion(question.isSkipped, question.text)"
                />
            </div>
        </div>
    </div>
</template>
