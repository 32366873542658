<script setup lang="ts">
import type { ComparisonItem } from '@core';
import { injectPloc } from '@core';
import { goTo } from '@/utils/url';
import { COMPARISON } from '@/data/urls';
import snippets from '@/utils/snippets';

import FloatingPanel from '@/components/FloatingPanel.vue';
import SelectionList from '@/components/SelectionList.vue';
import InstitutionSiteSmall from '@/components/items/InstitutionSiteSmall.vue';
import Overlay from '@/components/Overlay.vue';

import { COMPARISON_PLOC } from '@/utils';
import Note from '@/components/Note.vue';

const comparisonPloc = injectPloc(COMPARISON_PLOC);

const open = () => comparisonPloc.openPanel();
const close = () => comparisonPloc.closePanel();
const removeItem = (item: ComparisonItem) => comparisonPloc.remove(item);

function confirm() {
    goTo(COMPARISON, [comparisonPloc.state.comparingItems]);
    close();
}
</script>

<template>
    <FloatingPanel
        :is-open="comparisonPloc.state.isPanelOpen"
        :is-hidden="comparisonPloc.isEmpty() || comparisonPloc.state.isPanelHidden"
        :badge="comparisonPloc.getCount()"
        :title="snippets.get('compare_institutionSites')"
        :button-text="snippets.get('view_comparison')"
        :button-disabled="comparisonPloc.getCount() < 2"
        @open="open"
        @close="close"
        @confirm="confirm"
    >
        <Note v-if="comparisonPloc.isFull()" :text="`Je kunt maximaal ${comparisonPloc.state.max} organisaties vergelijken met elkaar`" />
        <Note v-if="comparisonPloc.getCount() === 1" text="Gelieve nog minstens 1 organisatie toe te voegen om de vergelijking te starten" />
        <SelectionList
            class="c-selection-list--divided"
            :items="comparisonPloc.state.comparingItems"
            @remove="removeItem"
        >
            <template #default="{ item }">
                <InstitutionSiteSmall :institution-site="item" />
            </template>
        </SelectionList>
    </FloatingPanel>
    <Overlay
        v-if="comparisonPloc.state.isPanelOpen && !comparisonPloc.isEmpty()"
        @close="close"
    />
</template>
