import { useQuery } from '@tanstack/vue-query';

import { reviewApi } from '../../api';
import { queryKeys } from '../../common/utils/constants';
import { reviewReportReasonDisplayMap } from '../../common/enums/ReviewReportReason';

export default function getReviewReportReasons() {
    const { data, isPending } = useQuery({
        queryKey: [queryKeys.public.review.GET_REVIEW_REPORT_REASONS],
        queryFn: () => reviewApi.getReviewReportReasons(),
        throwOnError: true,
        select: (response) => {
            return response.data.map((x) => {
                return {
                    value: x.value,
                    displayValue: reviewReportReasonDisplayMap[x.name],
                };
            });
        },
    });

    return {
        isPendingReviewReportReasons: isPending,
        reasons: data,
    };
}
