<script setup lang="ts">
import { injectPloc } from '@core';
import Notification from '@/components/persistent-layer/Notification.vue';

import { NOTIFICATION_PLOC } from '@/utils';

const notificationPloc = injectPloc(NOTIFICATION_PLOC)!;
</script>

<template>
    <div class="fixed top-0 left-auto right-auto vp6:top-4 vp6:right-4 vp6:left-auto vp9:top-8 vp9:right-8 vp9:left-auto gap-4 flex flex-col z-[9999] c-notification-stack">
        <div v-for="notification in notificationPloc.state.notifications" :key="notification.id">
            <Notification
                :notification="notification"
            />
        </div>
    </div>
</template>
