export enum ReviewReportReason {
    Language = 'Language',
    PersonalData = 'PersonalData',
    Advertisement = 'Advertisement',
    Other = 'Other',
}

type EnumMap = Partial<{ [key in ReviewReportReason]: string }>;

export const reviewReportReasonDisplayMap: EnumMap = {
    [ReviewReportReason.Language]: 'Taal',
    [ReviewReportReason.PersonalData]: 'Persoonlijke gegevens',
    [ReviewReportReason.Advertisement]: 'Reclame',
    [ReviewReportReason.Other]: 'Andere',
};
