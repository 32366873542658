import { useMutation } from '@tanstack/vue-query';
import { reviewCommentApi } from '../../../api';

import type { CreateReviewCommentRequest } from '../../../api/review-comment/requests/CreateReviewCommentRequest';
import { NotificationSeverity } from '../../../features';
import { dependenciesLocator } from '../../../common';

const notificationPloc = dependenciesLocator.provideNotificationPloc();

function useCreateReviewComment() {
    const { mutate, isPending, isSuccess } = useMutation({
        mutationFn: (request: CreateReviewCommentRequest) => reviewCommentApi.createReviewComment(request),
        onError(error) {
            notificationPloc.notify({
                title: 'API Error',
                text: error.message,
                severity: NotificationSeverity.Error,
            });
        },
        retry: false,
    });

    return {
        createReviewComment: mutate,
        isCreatingReviewComment: isPending,
        isSuccessCreatingReviewComment: isSuccess,
    };
}
export default useCreateReviewComment;
