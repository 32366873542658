<script setup lang="ts">
import Icon from '@/components/Icon.vue';

const props = defineProps({
    /*
    * The items in the selection list
    * */
    items: {
        type: Array,
        default: () => [],
    },
});

const emits = defineEmits(['remove']);

function removeItem(item: any) {
    emits('remove', item);
}
</script>

<template>
    <div class="c-selection-list">
        <div v-for="(item, index) in props.items" :key="index" class="c-selection-list__item">
            <slot :item="item" />
            <Icon name="close" class="c-selection-list__close-icon" @click.prevent="e => removeItem(item)" />
        </div>
    </div>
</template>
