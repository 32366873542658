import { useQuery } from '@tanstack/vue-query';

import type { Ref } from 'vue';
import { reviewCommentApi } from '../../api';
import { queryKeys } from '../../common/utils/constants';
import type { Nullable } from '../../common/types';

function useGetReviewForCreateComment(reviewId: Ref<Nullable<number> | undefined>, token: Ref<Nullable<string> | undefined>) {
    const { data, isPending, isSuccess } = useQuery({
        enabled: () => !!reviewId.value && !!token.value,
        queryKey: [queryKeys.public.reviewComment.GET_REVIEW_FOR_CREATE_COMMENT],
        queryFn: () => reviewCommentApi.getReviewForCreateComment(reviewId.value!, token.value!),
        retry: false,
        throwOnError: true,
        select: response => response!.data,
    });

    return {
        isPendingGetReviewForCreateComment: isPending,
        isSuccessGetReviewForCreateComment: isSuccess,
        reviewForCreateComment: data,
    };
}

export default useGetReviewForCreateComment;
