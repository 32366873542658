<script setup lang="ts">
import { computed } from 'vue';

import { injectPloc } from '@core';
import useGetProvinces from '@core/queries/location/getProvinces';
import snippetsUtil from '@/utils/snippets';
import { SEARCH_PLOC } from '@/utils';

import MultiSelectFilter from '@/components/institution-site-search/filters/MultiSelectFilter.vue';
import { stateToUrl } from '@/components/institution-site-search/utils/url-state';
import Collapsible from '@/components/Collapsible.vue';

const searchPloc = injectPloc(SEARCH_PLOC);

const { provinces, isPendingGetProvinces } = useGetProvinces();

const values = computed({
    get() {
        return searchPloc.state.selectedProvinces;
    },
    set(provinces: string[]) {
        searchPloc.selectProvinces(provinces);
        stateToUrl(searchPloc);
    },
});

const hasCustomValue = computed({
    get() {
        return searchPloc.state.isSearchingByCityString;
    },
    set(value: boolean) {
        if (value) {
            searchPloc.enableSearchByCityString();
            stateToUrl(searchPloc);
            return;
        }
        searchPloc.disableSearchByCityString();
        stateToUrl(searchPloc);
    },
});

const customValue = computed({
    get() {
        return searchPloc.state.customCityString;
    },
    set(value: string) {
        searchPloc.setCityString(value);
        stateToUrl(searchPloc);
    },
});

const badge = computed(() => {
    return searchPloc.state.selectedProvinces.length + (searchPloc.state.isSearchingByCityString ? 1 : 0);
});

function onCustomValueDebouncedUpdate() {
    searchPloc.loadInstitutionSites();
}
</script>

<template>
    <Collapsible v-if="!isPendingGetProvinces" :title="snippetsUtil.get('search_region')" :badge="badge">
        <MultiSelectFilter
            v-model:values="values"
            v-model:has-custom-value="hasCustomValue"
            v-model:custom-value="customValue"
            :options="provinces!"
            :allow-custom-value="true"
            custom-value-label="Specifieke gemeente of postcode"
            custom-value-placeholder="vb. 9000, Brugge, ..."
            @custom-value-debounced-update="onCustomValueDebouncedUpdate"
        />
    </Collapsible>
</template>
