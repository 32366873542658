'use strict';

const userLocation = {
    async get() {
        const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const { latitude, longitude } = position.coords;

        return { latitude, longitude };
    },
};

export default userLocation;
