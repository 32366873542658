<script setup lang="ts">
import { ref, watch } from 'vue';
import { formatDate } from '@core';
import type { Nullable } from '@core/common/types';
import useUpdateReviewComment from '@core/mutations/public/review-comment/updateReviewComment';
import useGetReviewCommentForEdit from '@core/queries/review-comment/getReviewCommentForEdit';
import CreateReviewTitle from '@/components/create-review/components/CreateReviewTitle.vue';
import snippets from '@/utils/snippets';
import Button from '@/components/Button.vue';
import Confirmation from '@/components/create-review-comment/Confirmation.vue';
import { Icon } from '@/components';
import Tag from '@/components/Tag.vue';

const reviewCommentId = defineModel<Nullable<number>>('reviewCommentId');
const token = defineModel<Nullable<string>>('token');
const showGuidelinesDialog = ref(false);

const { reviewCommentForEdit, isPendingGetReviewCommentForEdit, isSuccessGetReviewCommentForEdit } = useGetReviewCommentForEdit(reviewCommentId, token);
const { updateReviewComment, isSuccessUpdatingReviewComment } = useUpdateReviewComment();

watch(isPendingGetReviewCommentForEdit, () => {
    if (!isSuccessGetReviewCommentForEdit.value)
        window.location.href = '/';
});

function handleSubmit() {
    updateReviewComment({
        reviewCommentId: reviewCommentId.value!,
        editToken: token.value!,
        commentText: reviewCommentForEdit.value!.commentText,
    });
}
</script>

<template>
    <main v-if="isSuccessGetReviewCommentForEdit">
        <div class="flex pt-0 vp7:pt-[108px] pb-[100px] vp7:pb-0 min-h-screen">
            <div class="flex justify-center w-full relative overflow-hidden vp9:u-bg-shape-tl vp9:u-bg-shape-tr">
                <div class="layout bound w-full">
                    <div v-if="!isSuccessUpdatingReviewComment" class="bg-white flex flex-col items-center gap-8 p-8 vp7:rounded-[20px] self-start">
                        <CreateReviewTitle
                            :title="snippets.get('edit_review_comment_title')"
                            :subtitle="snippets.get('edit_review_comment_subtitle', {
                                createDate: formatDate(reviewCommentForEdit!.createDate, 'DD/MM/YYYY'),
                                institutionSiteName: reviewCommentForEdit!.institutionSiteName,
                            })"
                        />

                        <div class="u-bg-box">
                            <div class="flex gap-2 items-start">
                                <span class="c-label c-label--large block w-full">{{ snippets.get('create_review_comment_score_title') }}</span>
                                <span class="c-tag typo-tag bg-white">
                                    {{ reviewCommentForEdit!.score }}/5
                                </span>
                            </div>
                        </div>

                        <div class="u-bg-box ">
                            <span class="c-label c-label--large block w-full">{{ snippets.get('create_review_comment_review_title') }}</span>
                            <div class="s-wysiwyg">
                                <ul class="c-review-comment_review-list">
                                    <li>{{ formatDate(reviewCommentForEdit!.createDate, 'DD/MM/YYYY') }}</li>
                                    <li>{{ reviewCommentForEdit!.experienceText }}</li>
                                    <li v-for="answersPerCategory in reviewCommentForEdit!.answersPerCategories" :key="answersPerCategory.category.code">
                                        <Tag
                                            class="typo-tag c-tag--smal typo-small"
                                            :text="`${answersPerCategory.category.name} ${answersPerCategory.score}`"
                                        />
                                        <div v-for="answer in answersPerCategory.answers" :key="answer.questionText">
                                            <span>{{ answer.remark }}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="u-bg-box">
                            <div class="c-input-group">
                                <div class="mb-8 flex justify-between">
                                    <span class="c-label c-label--large">{{ snippets.get('create_review_comment_text_title') }}</span>
                                    <div class="c-card__links">
                                        <a class="c-link  c-card__link c-link--animated" href="#" target="_self" @click="showGuidelinesDialog = true">
                                            <span class="c-link__text">
                                                {{ snippets.get('create_review_accept_guidelines_card_cta') }}
                                            </span>
                                            <Icon class="c-link__icon" name="chevron-right" />
                                        </a>
                                    </div>
                                </div>
                                <textarea id="comment-text" v-model="reviewCommentForEdit!.commentText" autofocus required class="c-input" name="comment-text" rows="6" :placeholder="snippets.get('create_review_comment_text_placeholder')" />
                            </div>
                        </div>

                        <div class="w-full">
                            <div class="w-full vp9:w-auto flex gap-4 justify-end fixed bottom-0 left-0 right-0 px-4 py-6 bg-white border-t border-t-light-grey-2 vp7:relative vp7:p-0 z-50 vp7:border-none">
                                <Button
                                    class="c-button--secondary min-w-auto"
                                    :text="snippets.get('edit_review_comment_submit')"
                                    :disabled="!reviewCommentForEdit!.commentText"
                                    @click="handleSubmit"
                                />
                            </div>
                        </div>
                    </div>
                    <Confirmation v-else :institution-site-name="reviewCommentForEdit!.institutionSiteName" />
                </div>
            </div>
        </div>
    </main>
</template>
