<script setup lang="ts">
import { computed, ref } from 'vue';

import getReviewRelationTypes from '@core/queries/review/getReviewerRelationTypes';
import { injectPloc } from '@core';
import CreateReviewTitle from '../components/CreateReviewTitle.vue';
import { CREATE_REVIEW_PLOC } from '@/utils';
import snippets from '@/utils/snippets';

import GroupedSelect from '@/components/GroupedSelect.vue';
import CreateReviewButtonNavigation from '@/components/create-review/components/CreateReviewButtonNavigation.vue';
import RadioButtonGroup from '@/components/RadioButtonGroup.vue';
import { pushToMatomo } from '@/utils/matomo';

const createReviewPloc = injectPloc(CREATE_REVIEW_PLOC)!;

const { areReviewRelationTypesLoading, reviewRelationTypes } = getReviewRelationTypes();

const radioButtonYesAnswer = snippets.get('create_review_reviewer_capacity_support_answerYes');
const radioButtonNoAnswer = snippets.get('create_review_reviewer_capacity_support_answerNo');
const isReviewerCaregiverRawValue = ref(createReviewPloc.state.reviewerRelationType === '' ? radioButtonYesAnswer : radioButtonNoAnswer);
const isReviewerCaregiver = computed(() => {
    return isReviewerCaregiverRawValue.value === radioButtonNoAnswer;
});

const customReviewerCareGiverRawValue = ref(createReviewPloc.state.customReviewerRelationType);

function handleSubmit() {
    createReviewPloc.state.isReviewerRelationTypeConfirmed = true;

    if (customReviewerCareGiverRawValue.value !== '')
        createReviewPloc.state.customReviewerRelationType = customReviewerCareGiverRawValue.value;

    pushToMatomo('formStep', createReviewPloc.state.institutionSite!.institutionType.name, snippets.get('create_review_reviewer_capacity_title'));
}

function handleRadioChange(rawValue: string) {
    if (rawValue === radioButtonYesAnswer)
        createReviewPloc.state.reviewerRelationType = '';
}
</script>

<template>
    <div class="bg-white flex flex-col items-center gap-8 p-8 vp7:rounded-[20px] self-start">
        <CreateReviewTitle
            :title="snippets.get('create_review_reviewer_capacity_title')"
            :subtitle="snippets.get('create_review_reviewer_capacity_subtitle')"
        />

        <form class="flex flex-col gap-8 vp9:gap-2 w-full" @submit.prevent="handleSubmit">
            <div style="display:grid;gap:16px;background-color:#FAFAFA;padding:32px">
                <RadioButtonGroup
                    id="isSupportPerson"
                    v-model="isReviewerCaregiverRawValue"
                    :options="
                        [
                            {
                                value: radioButtonYesAnswer,
                            },
                            {
                                value: radioButtonNoAnswer,
                            },
                        ]"
                    :label="snippets.get('create_review_reviewer_capacity_support_question')"
                    @update:model-value="handleRadioChange"
                />

                <GroupedSelect
                    v-if="isReviewerCaregiver && !areReviewRelationTypesLoading"
                    id="capacity"
                    v-model="createReviewPloc.state.reviewerRelationType"
                    :grouped-options="reviewRelationTypes!"
                    :label="snippets.get('create_review_reviewer_capacity_capacity_name_question')"
                    :placeholder="snippets.get('create_review_reviewer_capacity_capacity_name_placeholder')"
                />

                <div v-if="createReviewPloc.state.reviewerRelationType === 'Andere'" class="flex flex-col vp9:flex-row gap-6 vp9:gap-2">
                    <div class="c-input-group">
                        <label for="other" class="c-label" :value="snippets.get('create_review_reviewer_other_field_title')" />
                        <input id="other" v-model="customReviewerCareGiverRawValue" maxlength="100" :placeholder="snippets.get('create_review_reviewer_other_field_placeholder')" class="c-input" type="text" name="other">
                    </div>
                </div>
            </div>
        </form>

        <div class="w-full">
            <CreateReviewButtonNavigation
                :is-next-disabled="isReviewerCaregiver && !createReviewPloc.state.reviewerRelationType"
                @next-clicked="handleSubmit"
            />
        </div>
    </div>
</template>
