<script setup lang="ts">
import type { Notification } from '@core';
import { injectPloc } from '@core';
import Icon from '@/components/Icon.vue';
import { NOTIFICATION_PLOC } from '@/utils';

const props = defineProps({
    /*
    * The notification data
    * */
    notification: {
        type: Object as () => Notification,
        required: true,
    },
});

const notificationPloc = injectPloc(NOTIFICATION_PLOC)!;

function remove() {
    notificationPloc.remove(props.notification);
}
</script>

<template>
    <div class="c-notification" :class="`c-notification--${props.notification.severity}`">
        <Icon :name="`notification-${props.notification.severity}`" class="c-notification__severity-icon" />
        <div class="c-notification__content">
            <div v-if="props.notification.title" class="c-notification__title">
                {{ props.notification.title }}
            </div>
            <div>{{ props.notification.text }}</div>
        </div>
        <Icon
            name="close"
            class="c-notification__close-icon"
            @click="remove"
        />
    </div>
</template>
