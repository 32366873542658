function disable() {
    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';
}

function enable() {
    document.body.style.height = '';
    document.body.style.overflow = '';
}

export default {
    disable,
    enable,
};
