<script setup lang="ts">
import { ref, watch } from 'vue';

import { injectPloc } from '@core';
import { SEARCH_PLOC } from '@/utils';

import { stateToUrl } from '@/components/institution-site-search/utils/url-state';
import RadioButtonGroup from '@/components/RadioButtonGroup.vue';
import snippets from '@/utils/snippets';
import Collapsible from '@/components/Collapsible.vue';

const searchPloc = injectPloc(SEARCH_PLOC);

const sortProperties = [
    { displayValue: 'Willekeurig', value: 'ran' },
    { displayValue: 'Naam (a-z)', value: 'name' },
    { displayValue: 'Naam (z-a)', value: '-name' },
    { displayValue: 'Cijfer (hoog-laag)', value: '-overallScore' },
    { displayValue: 'Cijfer (laag-hoog)', value: 'overallScore' },
    { displayValue: 'Aantal waarderingen (hoog-laag)', value: '-reviewCount' },
    { displayValue: 'Aantal waarderingen (laag-hoog)', value: 'reviewCount' },
];

const sortProperty = ref(sortProperties.find(property => property.value === searchPloc.state.sortBy)?.value || 'ran');

watch(sortProperty, (newValue) => {
    const option = sortProperties.find(property => property.value === newValue);
    if (option) {
        searchPloc.sortBy(option.value);
        stateToUrl(searchPloc);
    }
});
</script>

<template>
    <Collapsible :title="snippets.get('sort_by')">
        <RadioButtonGroup
            id="selected-sort"
            v-model="sortProperty"
            :options="sortProperties"
        />
    </Collapsible>
</template>
