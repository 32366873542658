import { api } from '../../common/domain';
import type { ReviewForCreateCommentResponse } from './responses/ReviewForCreateCommentResponse';
import type { CreateReviewCommentRequest } from './requests/CreateReviewCommentRequest';
import type { UpdateReviewCommentRequest } from './requests/UpdateReviewCommentRequest';
import type { ReviewCommentForEditResponse } from './responses/ReviewCommentForEditResponse';

function getReviewForCreateComment(reviewId: number, token: string) {
    return (api().get<ReviewForCreateCommentResponse>(`review-comment/${reviewId}/create/${token}`));
}

function getReviewCommentForEdit(reviewCommentId: number, editToken: string) {
    return (api().get<ReviewCommentForEditResponse>(`review-comment/${reviewCommentId}/edit/${editToken}`));
}

function createReviewComment(request: CreateReviewCommentRequest) {
    return api().post('review-comment', request);
}

function updateReviewComment(request: UpdateReviewCommentRequest) {
    return api().put('/review-comment', request);
}

export default {
    getReviewForCreateComment,
    getReviewCommentForEdit,
    createReviewComment,
    updateReviewComment,
};
