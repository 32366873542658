export const reviewAnswerRatings = [
    {
        value: 1,
        background: 'not-good',
        text: '1 - Niet goed',
    },
    {
        value: 2,
        background: 'could-be-better',
        text: '2 - Kan beter',
    },
    {
        value: 3,
        background: 'could-be-better',
        text: '3 - Oké',
    },
    {
        value: 4,
        background: 'okay',
        text: '4 - Goed',
    },
    {
        value: 5,
        background: 'super',
        text: '5 - Super',
    },
];
