<script setup lang="ts">
import { injectPloc } from '@core';
import Note from '@/components/Note.vue';
import InstitutionSiteAdvanced from '@/components/items/InstitutionSiteAdvanced.vue';
import Error from '@/components/Error.vue';
import snippets from '@/utils/snippets';

import { SEARCH_PLOC } from '@/utils';

const searchPloc = injectPloc(SEARCH_PLOC);
</script>

<template>
    <Note v-if="searchPloc.state.institutionSites.length" class="mb-4" text="De sortering wordt niet beïnvloed door de organisatie." />
    <div v-if="searchPloc.state.institutionSites.length" class="gap-4 mb-4" :class="{ 'flex flex-col': searchPloc.state.viewMode === 'default', 'grid grid-cols-2': searchPloc.state.viewMode === 'card' }">
        <InstitutionSiteAdvanced
            v-for="institutionSite in searchPloc.state.institutionSites"
            :key="institutionSite.id"
            :institution-site="institutionSite"
            :type="searchPloc.state.viewMode"
        />
    </div>
    <Error
        v-if="!searchPloc.state.institutionSites.length"
        :title="snippets.get('institutionSite_placeholder_title')"
        :text="snippets.get('institutionSite_placeholder_description')"
    />
</template>
