<!-- eslint-disable prefer-promise-reject-errors -->
<script setup lang="ts">
import { computed, reactive } from 'vue';
import useCreateContactFormEntry from '@core/mutations/public/contact/createContactFormEntry';
import Confirmation from '@/components/contact/Confirmation.vue';
import snippets from '@/utils/snippets';

const props = defineProps({
    consentLabel: {
        type: String,
    },
});

const { createContactFormEntry, isCreatingContactFormEntry, isSuccessCreatingContactFormEntry } = useCreateContactFormEntry();

interface FormFields {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    message: string
    consent: boolean
}

const formFields = reactive<FormFields>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
    consent: false,
});

function validatePhone() {
    const phoneNumberPattern = /^\+?[0-9]{10,11}$/;
    return formFields.phoneNumber === '' || phoneNumberPattern.test(formFields.phoneNumber);
}

function validateEmail() {
    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailFormat.test(formFields.email.trim());
}

const isFormValid = computed(() => {
    return formFields.firstName.trim() !== ''
        && formFields.lastName.trim() !== ''
        && validateEmail()
        && validatePhone()
        && formFields.message.trim() !== ''
        && formFields.consent;
});

async function onSubmit() {
    if (!isFormValid.value)
        return;

    createContactFormEntry(formFields);
}
</script>

<template>
    <Confirmation v-if="isSuccessCreatingContactFormEntry" />
    <form v-else class="flex flex-col gap-4 js-form" @submit.prevent="onSubmit">
        <div class="flex flex-col vp9:flex-row gap-6 vp9:gap-2">
            <div class="c-input-group">
                <label for="firstname" class="c-label">{{ snippets.get('contact_firstName') }}</label>
                <input id="firstname" v-model="formFields.firstName" class="c-input" type="text" name="firstname">
            </div>
            <div class="c-input-group">
                <label for="lastname" class="c-label">{{ snippets.get('contact_lastName') }}</label>
                <input id="lastname" v-model="formFields.lastName" class="c-input" type="text" name="lastname">
            </div>
        </div>

        <div class="c-input-group">
            <label for="email" class="c-label">{{ snippets.get('contact_email') }}</label>
            <input id="email" v-model="formFields.email" class="c-input" type="text" name="email">
        </div>

        <div class="c-input-group">
            <label for="phone" class="c-label">{{ snippets.get('contact_phone') }}</label>
            <input id="phone" v-model="formFields.phoneNumber" class="c-input" type="text" name="phone">
        </div>

        <div class="c-input-group">
            <label for="message" class="c-label">{{ snippets.get('contact_message') }}</label>
            <textarea id="message" v-model="formFields.message" class="c-input" name="message" rows="4" placeholder="Jouw bericht" />
        </div>

        <div class="c-checkbox-group__item">
            <input id="consent" v-model="formFields.consent" class="c-checkbox-group__input" type="checkbox" name="consent">
            <label class="c-checkbox-group__label" for="consent">
                <span class="c-checkbox-group__icon" />
                <span v-html="props.consentLabel" />
            </label>
        </div>

        <div class="flex justify-end">
            <button type="submit" class="c-button c-button--secondary typo-button form-submit w-fit" :disabled="!isFormValid || isCreatingContactFormEntry" :class="{ 'is-loading': isCreatingContactFormEntry }">
                {{ snippets.get('contact_submit') }}
            </button>
        </div>
    </form>
</template>
