<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';

const props = defineProps({
    delay: {
        type: Number,
        default: 250,
    },
});
const isVisible = ref(false);

let timeout: NodeJS.Timeout;

onMounted(() => {
    timeout = setTimeout(() => {
        isVisible.value = true;
    }, props.delay);
});

onBeforeUnmount(() => clearTimeout(timeout));
</script>

<template>
    <span class="c-loader" :class="{ 'c-loader--visible': isVisible }" />
</template>
