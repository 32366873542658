import { useQuery } from '@tanstack/vue-query';

import { ReviewActions } from 'public/src/components/create-review/types/ReviewActions';
import { reviewApi } from '../../api';
import { queryKeys } from '../../common/utils/constants';

function getReviewForEdit(selectedReviewId: any, action: any, editToken?: any) {
    const { data, isPending, isSuccess, isLoading } = useQuery({
        enabled: () => selectedReviewId && action.value === ReviewActions.Edit && !!editToken.value,
        queryKey: [queryKeys.public.review.GET_REVIEW_FOR_EDIT, editToken.value],
        queryFn: () => {
            return reviewApi.getReviewByEditToken(selectedReviewId.value, editToken.value);
        },
        retry: false,
        throwOnError: true,
        select: response => response!.data,
    });

    return {
        isPendingReviewForEdit: isPending,
        isLoadingReviewForEdit: isLoading,
        isSuccessReviewForEdit: isSuccess,
        reviewForEdit: data,
    };
}

export default getReviewForEdit;
