<script setup lang="ts">
import snippets from '@/utils/snippets';

import Button from '@/components/Button.vue';
import CreateReviewTitle from '@/components/create-review/components/CreateReviewTitle.vue';

const handleClose = function () {
    window.location.href = '/';
};
</script>

<template>
    <div class="layout bound w-full">
        <div class="bg-white flex flex-col items-center gap-8 p-8 vp7:rounded-[20px] self-start">
            <CreateReviewTitle
                :title="snippets.get('publish_review_confirmation_title')"
            />

            <div class="u-bg-box ">
                <div class="s-wysiwyg">
                    <h5>{{ snippets.get('publish_review_confirmation_description_title') }}</h5>
                    <p>{{ snippets.get('publish_review_confirmation_description_text') }}</p>
                </div>
            </div>

            <div class="w-full">
                <Button
                    class="c-button--secondary min-w-auto"
                    :text="snippets.get('publish_review_confirmation_close')"
                    @click="handleClose"
                />
            </div>
        </div>
    </div>
</template>
