import { api } from '../../common/domain';
import type { CreateContactFormEntryRequest } from './requests/CreateContactFormEntryRequest';

function createContactFormEntry(request: CreateContactFormEntryRequest) {
    return api().post('/contact', request);
}

export default {
    createContactFormEntry,
};
