<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { injectPloc } from '@core';
import { SEARCH_PLOC } from '@/utils/index.js';

import SearchList from '@/components/institution-site-search/components/SearchList.vue';
import snippets from '@/utils/snippets';
import SearchField from '@/components/SearchField.vue';
import FilterStatus from '@/components/institution-site-search/components/FilterStatus.vue';
import LocationFilter from '@/components/institution-site-search/filters/LocationFilter.vue';
import TypeFilter from '@/components/institution-site-search/filters/SectorFilter.vue';
import ReviewScoreFilter from '@/components/institution-site-search/filters/ReviewScoreFilter.vue';
import { stateToUrl, urlToState } from '@/components/institution-site-search/utils/url-state';
import FilterStack from '@/components/institution-site-search/components/FilterStack.vue';
import SortByFilter from '@/components/institution-site-search/filters/SortByFilter.vue';

const searchPloc = injectPloc(SEARCH_PLOC);

const searchString = computed({
    get() {
        return searchPloc.state.searchString;
    },
    set(value) {
        searchPloc.setSearchString(value);
    },
});

function onDebouncedSearch() {
    searchPloc.searchByString(searchString.value);
    stateToUrl(searchPloc);
}

onMounted(() => {
    urlToState(searchPloc);
    searchPloc.loadInstitutionSites();
});
</script>

<template>
    <div class="layout bound-x bg-beige z-[1] relative">
        <div class="grid vp9:grid-cols-3 grid-cols-1 vp9:gap-6 gap-x-0 gap-y-4">
            <div class="flex flex-col gap-8 bg-white rounded-[20px] h-fit p-8">
                <FilterStatus class="hidden vp9:flex" />
                <SearchField
                    v-model="searchString"
                    :placeholder="snippets.get('search_institutionSite')"
                    :debounce-time="300"
                    @debounced-update="onDebouncedSearch"
                />
                <FilterStack>
                    <TypeFilter />
                    <LocationFilter />
                    <ReviewScoreFilter />
                    <SortByFilter class="vp9:hidden" />
                </FilterStack>
            </div>
            <SearchList />
        </div>
    </div>
</template>
