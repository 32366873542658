import { useQuery } from '@tanstack/vue-query';

import { reviewApi } from '../../api';
import type { GroupedOptions } from '../../common/types/GroupedOptions';
import { queryKeys } from '../../common/utils/constants';

function getReviewerRelationTypes() {
    const { data, isLoading } = useQuery({
        queryKey: [queryKeys.public.review.GET_REVIEWER_RELATIONTYPES],
        queryFn: () => reviewApi.getReviewerRelationTypes(),
        throwOnError: true,
        staleTime: 5000,
        select: (response) => {
            return response!.data.reduce((acc: GroupedOptions[], relationType) => {
                const group = acc.find(g => g.groupLabel === relationType.groupName);

                if (group)
                    group.options.push(relationType.name);
                else
                    acc.push({ groupLabel: relationType.groupName, options: [relationType.name] });

                return acc;
            }, []);
        },
    });

    return {
        areReviewRelationTypesLoading: isLoading,
        reviewRelationTypes: data,
    };
}

export default getReviewerRelationTypes;
