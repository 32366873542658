<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';

import getInstitutionSitesForComparison from '@core/queries/institutionSite/getInstitutionSitesForComparison';
import { injectPloc } from '@core';
import type {
    InstitutionSiteForComparison,
} from '@core/api/institutionSite/responses/InstitutionSiteCompareResponse';
import { COMPARISON_PLOC } from '@/utils';

import snippets from '@/utils/snippets';

import Placeholder from '@/components/comparison/components/Placeholder.vue';
import InstitutionSiteSelectModal from '@/components/comparison/components/InstitutionSiteSelectModal.vue';
import Rating from '@/components/Rating.vue';
import Button from '@/components/Button.vue';
import { stateToUrl } from '@/components/comparison/utils/url-state';
import { Icon } from '@/components';

const comparisonPloc = injectPloc(COMPARISON_PLOC);

const institutionSitesIds = ref<string[]>([]);
const scrollPos = ref<number>(0);
const canClickLeft = ref<boolean>(false);
const canClickRight = ref<boolean>(true);
const { isPendingComparison, isSuccessComparison, comparison } = getInstitutionSitesForComparison(institutionSitesIds);

watch(isSuccessComparison, () => {
    if (isSuccessComparison.value && comparison!.value!.institutionSitesForComparison?.length) {
        comparisonPloc.setType(comparison!.value!.institutionSitesForComparison[0].institutionType.name);
        comparisonPloc.setItems(comparison!.value!.institutionSitesForComparison!.map(x => ({
            id: x.id,
            type: x.institutionType.name,
            overallScore: x.overallScore,
        })));
    }
});

onMounted(() => {
    getRouteParams();

    if (!institutionSitesIds.value.values.length)
        comparisonPloc.clearItems();
});

function getRouteParams() {
    const params = new URLSearchParams(window.location.search);
    institutionSitesIds.value = params.getAll('orgId');
}

function close() {
    comparisonPloc.changeState({
        ...comparisonPloc.state,
        isAddingItems: false,
        isMakingNewComparison: false,
    });
}

function remove(institutionSite: InstitutionSiteForComparison) {
    comparisonPloc.removeItem(institutionSite.id);
    stateToUrl(comparisonPloc);
    getRouteParams();
}

function confirmNew() {
    comparisonPloc.setItems(comparisonPloc.state.selectedItems);
    comparisonPloc.clearSelection();
    stateToUrl(comparisonPloc);
    getRouteParams();
}

function confirmAdd() {
    comparisonPloc.addItems(comparisonPloc.state.selectedItems);
    comparisonPloc.clearSelection();
    stateToUrl(comparisonPloc);
    getRouteParams();
}

function getScore(institutionSite: InstitutionSiteForComparison, categoryCode: string) {
    const averageScore = institutionSite.averageScoresPerCategory!.find(c => c.categoryCode === categoryCode);

    return (averageScore ? averageScore.averageScore : '');
}

const selectedCount = computed(() => {
    return (comparisonPloc.getSelectedCount() ? ` (${comparisonPloc.getSelectedCount()})` : '');
});

function scrollLeft() {
    const comparisonContainer = document.getElementById('compare-items');

    if (comparisonContainer) {
        comparisonContainer.scrollBy({
            left: -(comparisonContainer.scrollWidth - comparisonContainer.clientWidth),
            behavior: 'smooth',
        });
        scrollPos.value -= comparisonContainer.scrollWidth - comparisonContainer.clientWidth;
        CheckButtons(comparisonContainer);
    }
}

function scrollRight() {
    const comparisonContainer = document.getElementById('compare-items');

    if (comparisonContainer) {
        comparisonContainer.scrollBy({
            left: (comparisonContainer.scrollWidth - comparisonContainer.clientWidth),
            behavior: 'smooth',
        });
        scrollPos.value += comparisonContainer.scrollWidth - comparisonContainer.clientWidth;
        CheckButtons(comparisonContainer);
    }
}

function CheckButtons(comparisonContainer: HTMLElement) {
    if (scrollPos.value === 0) {
        canClickRight.value = true;
        canClickLeft.value = false;
    }

    if ((comparisonContainer.scrollWidth - comparisonContainer.clientWidth) === scrollPos.value) {
        canClickRight.value = false;
        canClickLeft.value = true;
    }
}

onMounted(() => {
    comparisonPloc.clear();
});
</script>

<template>
    <div class="flex flex-col vp6:flex-row justify-center gap-2 vp6:gap-4 bg-beige comparison-buttons">
        <Button
            v-if="institutionSitesIds.length < comparisonPloc.state.max"
            :text="snippets.get('add_to_comparison')"
            @click="_ => comparisonPloc.enableAddingItems()"
        />
        <Button :text="snippets.get('start_new_comparison')" class="c-button--outline" @click="_ => comparisonPloc.enableMakingNewComparison()" />
    </div>
    <section class="layout bg bound-x container-grid section-t-md section-b-xl flow-md bg-beige relative u-bg-shape-bl u-bg-shape-br">
        <Placeholder v-if="!comparisonPloc.state.addedItems?.length || isPendingComparison" />
        <div v-if="comparisonPloc.state.addedItems?.length && isSuccessComparison" class="block relative">
            <div class="flex justify-end items-end">
                <div class="flex gap-2 vp7:hidden pb-2">
                    <button class="c-button typo-button c-button--icon c-button--secondary" :disabled="!canClickLeft" @click="scrollLeft">
                        <span class="c-icon c-button__icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.2638 6.7972L9.03048 10.0305L12.2638 13.2639C12.5888 13.5889 12.5888 14.1139 12.2638 14.4389C11.9388 14.7639 11.4138 14.7639 11.0888 14.4389L7.26382 10.6139C6.93882 10.2889 6.93882 9.76387 7.26382 9.43887L11.0888 5.61387C11.4138 5.28887 11.9388 5.28887 12.2638 5.61387C12.5805 5.93887 12.5888 6.4722 12.2638 6.7972Z" />
                            </svg>
                        </span>
                    </button>
                    <button class="c-button typo-button c-button--icon c-button--secondary" :disabled="!canClickRight" @click="scrollRight">
                        <span class="c-icon c-button__icon ">
                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.73618 13.2028L10.9695 9.96947L7.73618 6.73613C7.41118 6.41113 7.41118 5.88613 7.73618 5.56113C8.06118 5.23613 8.58618 5.23613 8.91118 5.56113L12.7362 9.38613C13.0612 9.71113 13.0612 10.2361 12.7362 10.5611L8.91118 14.3861C8.58618 14.7111 8.06118 14.7111 7.73618 14.3861C7.41951 14.0611 7.41118 13.5278 7.73618 13.2028Z" />
                            </svg>
                        </span>
                    </button>
                </div>
            </div>

            <div id="compare-items" class="flex vp7:grid gap-4 vp7:gap-0 vp7:grid-cols-4 vp7:bg-white vp7:rounded-[20px] overflow-auto vp7:overflow-hidden">
                <div class="c-compare-item  c-compare-item--row-head">
                    <div class="c-compare-item__header" />

                    <div class="c-compare-item__ratings">
                        <div class="c-compare-item__rating">
                            <div class="c-compare-item__rating-title">
                                {{ snippets.get('comparison_rating_title') }}
                            </div>
                        </div>
                        <div v-for="category in comparison!.reviewAnswerCategories" :key="category.code" class="c-compare-item__rating">
                            <div class="c-compare-item__rating-title">
                                {{ category.shortName }}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-for="institutionSite in comparison!.institutionSitesForComparison" :key="institutionSite.id" class="c-compare-item">
                    <div class="c-compare-item__header">
                        <button class="c-compare-item__close">
                            <Icon name="close" class="c-compare-item__close-icon" @click="_ => remove(institutionSite)" />
                        </button>
                        <div class="c-compare-item__title">
                            {{ institutionSite.name }}
                        </div>
                        <div class="c-compare-item__subtitle">
                            {{ institutionSite.zipcode }}, {{ institutionSite.city }}
                        </div>
                        <div class="c-compare-item__text">
                            {{ institutionSite.reviewCount }} waarderingen
                        </div>
                    </div>

                    <div class="c-compare-item__ratings">
                        <div class="c-compare-item__rating">
                            <div class="c-compare-item__rating-title">
                                {{ snippets.get('comparison_rating_title') }}
                            </div>
                            <div class="c-compare-item__rating-score">
                                <Rating v-if="institutionSite.overallScore !== null" :value="institutionSite.overallScore!" />
                                <div v-else class="px-8">
                                    -
                                </div>
                            </div>
                        </div>
                        <div v-for="category in comparison!.reviewAnswerCategories" :key="category.code" class="c-compare-item__rating">
                            <div class="c-compare-item__rating-title">
                                {{ category.shortName }}
                            </div>
                            <div class="c-compare-item__rating-score">
                                <Rating v-if="getScore(institutionSite, category.code)" :value="getScore(institutionSite, category.code) as number" class="c-tag--transparent" />
                                <div v-else class="px-8">
                                    -
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <InstitutionSiteSelectModal
        v-if="comparisonPloc.state.isAddingItems"
        :min="1"
        :max="comparisonPloc.state.max - comparisonPloc.state.addedItems.length"
        :title="snippets.get('add_to_comparison')"
        :confirm-button-text="snippets.get('add_to_comparison') + selectedCount"
        @close="close"
        @confirm="confirmAdd"
    />
    <InstitutionSiteSelectModal
        v-if="comparisonPloc.state.isMakingNewComparison"
        :min="2"
        :max="3"
        :title="snippets.get('start_new_comparison')"
        :confirm-button-text="snippets.get('start_new_comparison') + selectedCount"
        @close="close"
        @confirm="confirmNew"
    />
</template>
