<script setup lang="ts">
import { injectPloc } from '@core';
import { CREATE_REVIEW_PLOC } from '@/utils';
import snippets from '@/utils/snippets';
import Button from '@/components/Button.vue';

const props = defineProps({
    isNextDisabled: Boolean,
    previousCaption: String,
    nextCaption: String,
});

const emit = defineEmits(['previousClicked', 'nextClicked']);

const createReviewPloc = injectPloc(CREATE_REVIEW_PLOC)!;
</script>

<template>
    <div class="w-full vp9:w-auto flex gap-4 justify-between fixed bottom-0 left-0 right-0 px-4 py-6 bg-white border-t border-t-light-grey-2 vp7:relative vp7:p-0 z-50 vp7:border-none">
        <Button
            class="c-button--primary c-button--outline min-w-auto"
            :text="previousCaption ? previousCaption : snippets.get('create_review_previous')"
            @click="createReviewPloc.goToPreviousFixedStep(); emit('previousClicked')"
        />
        <Button
            class="c-button--secondary min-w-auto"
            :text="nextCaption ? nextCaption : snippets.get('create_review_next')"
            :disabled="props.isNextDisabled"
            @click="createReviewPloc.goToNextFixedStep();emit('nextClicked')"
        />
    </div>
</template>

<style scoped lang="scss">

</style>
