<script setup lang="ts">
import { computed } from 'vue';
import type { InstitutionSite } from '@core/features/search/domain/entities/InstitutionSite';

import { injectPloc } from '@core';
import snippets from '@/utils/snippets';
import Tag from '@/components/Tag.vue';
import Switch from '@/components/Switch.vue';

import { COMPARISON_PLOC } from '@/utils';
import { get as getUrl } from '@/utils/url';
import { INSTITUTION_SITE_DETAIL } from '@/data/urls';

const props = defineProps({
    institutionSite: {
        type: Object as () => InstitutionSite,
        required: true,
    },
    type: {
        type: String,
        default: 'default',
    },
});
const comparisonPloc = injectPloc(COMPARISON_PLOC);

const reviewGrammar = computed(() => {
    return props.institutionSite.reviewCount > 1 || props.institutionSite.reviewCount === 0 ? snippets.get('reviews') : snippets.get('review');
});

function toggle() {
    comparisonPloc.toggle(props.institutionSite);
}
</script>

<template>
    <div class="bg-white rounded-[20px] p-8 flex flex-col">
        <div class="flex pb-8 gap-2 items-start" :class="{ 'flex-col-reverse gap-8': type === 'card' }">
            <div class="grow">
                <a class="typo-h6 text-black" :href="getUrl(INSTITUTION_SITE_DETAIL, [props.institutionSite])">
                    {{ props.institutionSite.name }}
                </a>
                <p>{{ props.institutionSite.zipcode }} {{ props.institutionSite.city }}</p>
            </div>
            <Tag v-if="props.institutionSite.overallScore" :text="`${props.institutionSite.overallScore}/5`" />
        </div>
        <p>
            {{ props.institutionSite.reviewCount }} {{ reviewGrammar }}
        </p>
        <div class="flex flex-col gap-6 vp9:gap-0 vp9:flex-row justify-between" :class="{ 'flex-col gap-8 vp9:flex-col vp9:gap-6': type === 'card' }">
            <div class="flex flex-wrap gap-2 pt-2">
                <Tag :text="props.institutionSite.institutionType.name" class="c-tag--small typo-small" />
                <Tag
                    v-if="!props.institutionSite.overallScore"
                    key="score"
                    v-tooltip.top="
                        {
                            class: 'c-tooltip',
                            value: snippets.get('tooltip_institutionSite_no_score'),
                            hideDelay: 300,
                        }"
                    :text="snippets.get('no_average_score')"
                    icon="info"
                    class="c-tag--small c-tag--highlight typo-small"
                />
            </div>
            <div v-if="props.institutionSite.overallScore" class="flex items-center gap-2">
                <span class="cursor-pointer select-none" @click="toggle">
                    {{ snippets.get('compare_institutionSite') }}
                </span>
                <Switch
                    v-tooltip.top="
                        {
                            class: 'c-tooltip',
                            value: comparisonPloc.isFull() ? snippets.get('tooltip_toggle_institutionSite_max_length') : snippets.get('tooltip_toggle_institutionSite'),
                            hideDelay: 300,
                            disabled: comparisonPloc.isAdded(props.institutionSite) || comparisonPloc.canBeAdded(props.institutionSite),
                        }"
                    :checked="comparisonPloc.isAdded(props.institutionSite)"
                    :disabled="!comparisonPloc.isAdded(props.institutionSite) && !comparisonPloc.canBeAdded(props.institutionSite)"
                    :label="snippets.get('compare_institutionSite')"
                    @toggle="toggle"
                />
            </div>
        </div>
    </div>
</template>
