import { useQuery } from '@tanstack/vue-query';

import { institutionSiteApi } from '../../api';
import { queryKeys } from '../../common/utils/constants';
import type {
    GetInstitutionSiteReviewsRequest,
} from '../../api/institutionSite/requests/GetInstitutionSiteReviewsRequest';
import type { InstitutionSiteDetailReview } from '../../features';

export default function useGetInstitutionSiteDetailReviews(request: GetInstitutionSiteReviewsRequest) {
    const { data, isPending, isSuccess } = useQuery({
        enabled: () => !!request.institutionSiteId,
        queryKey: [queryKeys.public.institutionSite.GET_INSTITUTION_SITE_DETAIL_REVIEWS, request],
        queryFn: () => institutionSiteApi.getInstitutionSiteDetailPagedReviews(request),
        placeholderData: previousData => previousData,
        throwOnError: true,
        staleTime: 5000,
        select: (response) => {
            return {
                data: response!.data.results as InstitutionSiteDetailReview[],
                ...response.data.pagination,
            };
        },
    });

    return {
        isSuccessGetReviews: isSuccess,
        isPendingGetReviews: isPending,
        reviewsPaged: data,
    };
}
