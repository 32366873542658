<script setup lang="ts">
import type { InstitutionSiteSuggestion } from '@core';
import { onMounted, ref } from 'vue';
import { injectPloc } from '@core';
import CreateReviewTitle from '../components/CreateReviewTitle.vue';
import { CREATE_REVIEW_PLOC } from '@/utils';
import snippets from '@/utils/snippets';

import InstitutionSiteMedium from '@/components/items/InstitutionSiteMedium.vue';
import InstitutionSiteSuggestionSearch from '@/components/search-widget/components/InstitutionSiteSuggestionSearch.vue';
import Tag from '@/components/Tag.vue';

// import router from '@/router';
import CreateReviewButtonNavigation from '@/components/create-review/components/CreateReviewButtonNavigation.vue';
import { pushToMatomo } from '@/utils/matomo';
import Loader from '@/components/Loader.vue';

const isLoading = ref<boolean>(false);

// const route = useRoute();
const createReviewPloc = injectPloc(CREATE_REVIEW_PLOC)!;

function onInstitutionSiteSuggestionSelect(institutionSite: InstitutionSiteSuggestion) {
    createReviewPloc.setInstitutionSiteFromSuggestion(institutionSite.id).then(() => {
        pushToMatomo('formStep', createReviewPloc.state.institutionSite!.institutionType.name, snippets.get('create_review_select_institutionSite_title'));
    });
}

function handlePrevious() {
    const url = new URL(window.location.href.split('?')[0]);
    window.history.replaceState({}, '', url);
    createReviewPloc.reset();
}

function handleNext() {
    createReviewPloc.state.isInstitutionSiteConfirmed = true;
    pushToMatomo('formStep', createReviewPloc.state.institutionSite!.institutionType.name, snippets.get('create_review_select_institutionSite_next'));
}

onMounted(async () => {
    const params = new URLSearchParams(window.location.search);
    const institutionSiteId = params.get('id');

    if (institutionSiteId) {
        isLoading.value = true;
        setTimeout(async () => {
            await createReviewPloc.setInstitutionSiteFromSuggestion(Number.parseInt(institutionSiteId));
            createReviewPloc.state.isInstitutionSiteConfirmed = true;
            createReviewPloc.goToNextFixedStep();
            isLoading.value = false;
        }, 500);
    }
});
</script>

<template>
    <div v-if="isLoading" class="p-6 flex justify-center">
        <Loader />
    </div>
    <div v-else class="bg-white flex flex-col items-center gap-8 p-8 vp7:rounded-[20px] self-start">
        <CreateReviewTitle
            :title="snippets.get('create_review_select_institutionSite_title')"
            :subtitle="snippets.get('create_review_select_institutionSite_subtitle')"
        />

        <div class="px0 vp9:px-12 w-full z-[100]">
            <InstitutionSiteSuggestionSearch
                :placeholder="snippets.get('create_review_search_institutionSite')"
                :autofocus="true"
                @select="onInstitutionSiteSuggestionSelect"
            >
                <template #item="{ item, hasFocus }">
                    <InstitutionSiteMedium
                        :class="{ 'bg-light-grey': hasFocus }"
                        :institution-site="item"
                    />
                </template>
            </InstitutionSiteSuggestionSearch>
        </div>

        <div class="flex flex-col gap-8 vp9:gap-2 w-full">
            <div v-if="createReviewPloc.state.institutionSite" class="flex flex-col gap-8">
                <div class="c-label c-label--large block w-full mb-0">
                    {{ snippets.get('create_review_selected_institutionSite') }}
                </div>

                <div class="bg-light-grey rounded-[20px] p-8 flex flex-col">
                    <div class="flex pb-8 gap-2 items-start">
                        <div class="grow">
                            <h2 class="typo-h6">
                                {{ createReviewPloc.state.institutionSite.name }}
                            </h2>
                            <p>{{ createReviewPloc.state.institutionSite.zipcode }} {{ createReviewPloc.state.institutionSite.city }} </p>
                        </div>
                        <span v-if="createReviewPloc.canOverallReviewScoreBeShown()" class="c-tag typo-tag">
                            {{ createReviewPloc.state.institutionSite.overallScore }}/5
                        </span>
                    </div>
                    <p v-if="createReviewPloc.canOverallReviewScoreBeShown()">
                        <strong>{{ createReviewPloc.state.institutionSite.overallScore }}</strong>
                        ({{ createReviewPloc.state.institutionSite.reviewCount }} {{ snippets.get('reviews') }})
                    </p>
                    <div class="flex items-center justify-between">
                        <div class="flex flex-wrap gap-2 pt-2">
                            <Tag :text="createReviewPloc.state.institutionSite.institutionType.name" class="c-tag--small typo-small" />
                            <Tag
                                v-if="!createReviewPloc.canOverallReviewScoreBeShown()"
                                v-tooltip.top="
                                    {
                                        class: 'c-tooltip',
                                        value: snippets.get('tooltip_institutionSite_no_score'),
                                        hideDelay: 300,
                                    }"
                                :text="snippets.get('no_average_score')"
                                icon="info"
                                class="c-tag--small c-tag--highlight typo-small"
                            />
                        </div>
                    </div>
                </div>

                <CreateReviewButtonNavigation
                    :previous-caption="snippets.get('create_review_select_institutionSite_previous')"
                    :next-caption="snippets.get('create_review_select_institutionSite_next')"
                    @previous-clicked="handlePrevious"
                    @next-clicked="handleNext"
                />
            </div>
        </div>
    </div>
</template>
