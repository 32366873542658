import { useQuery } from '@tanstack/vue-query';

import { locationApi } from '../../api';
import { queryKeys } from '../../common/utils/constants';
import type { SelectOption } from '../../common/types';

export default function useGetProvinces() {
    const { data, isPending } = useQuery({
        queryKey: [queryKeys.public.location.GET_PROVINCES],
        queryFn: () => locationApi.getProvinces(),
        throwOnError: true,
        staleTime: Number.POSITIVE_INFINITY,
        select: (response) => {
            return response.data
                .sort((a, b) => a.name!.localeCompare(b.name!))
                .map(province => ({
                    displayValue: province.name,
                    value: province.name,
                })) as SelectOption[];
        },
    });

    return {
        isPendingGetProvinces: isPending,
        provinces: data,
    };
}
