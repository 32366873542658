<script setup lang="ts">
/**
 * This component renders text, but if it is longer then the given props.characterCount, truncates it
 * and adds a button to expand the text.
 *
 * Example usage:
 * <ExpandableText :text="yourText" :characterCount="140" />
 */
import { computed, ref } from 'vue';
import snippets from '@/utils/snippets';

import Link from '@/components/Link.vue';

const props = defineProps({
    characterCount: {
        type: Number,
        default: 250,
    },
    truncatingCharacter: {
        type: String,
        default: '…',
    },
    text: {
        type: String,
        required: true,
    },
});

const isExpanded = ref(false);

const isOverflowing = computed(() => props.text.length > props.characterCount);

const visibleText = computed(() => {
    if (!isOverflowing.value || isExpanded.value)
        return props.text;

    // @TODO we should probably make this a bit smarter so it doesn't truncate in the middle of a word or at awkward places like whitespace
    return props.text.substring(0, props.characterCount) + props.truncatingCharacter;
});

const expandLinkText = computed(() => (isExpanded.value ? snippets.get('show_less') : snippets.get('show_more')));

const toggle = () => isExpanded.value = !isExpanded.value;
</script>

<template>
    {{ visibleText }}
    <div v-if="isOverflowing" class="mt-4">
        <Link :text="expandLinkText" :icon="isExpanded ? 'chevron-up' : 'chevron-down'" @click="toggle" />
    </div>
</template>
