<script setup lang="ts">
import { computed, ref } from 'vue';
import { injectPloc } from '@core';
import { Icon } from '../../index';
import { CategoryStatus } from '../types/CategoryStatus';
import snippets from '@/utils/snippets';
import { CREATE_REVIEW_PLOC } from '@/utils';
import CreateReviewFormProgressItem from '@/components/create-review/components/CreateReviewFormProgressItem.vue';
import { FixedSteps } from '@/components/create-review/types/FixedSteps';
import GuidelinesModal from '@/components/create-review/components/GuidelinesModal.vue';

const createReviewPloc = injectPloc(CREATE_REVIEW_PLOC)!;

const categoriesStatusses = computed(() => {
    const categoryStatusMap: Record<string, CategoryStatus> = {};

    createReviewPloc.state.questions.forEach((question, index) => {
        const categoryCode = question.categoryCode;
        const isCurrent = index === createReviewPloc.state.currentActiveQuestion - 1
            && createReviewPloc.state.currentFixedStep === FixedSteps.Questions;
        const isAnswered = question.userScore !== null;
        const isSkipped = question.isSkipped;

        categoryStatusMap[categoryCode] = CategoryStatus.Pending;

        if (isCurrent)
            categoryStatusMap[categoryCode] = CategoryStatus.Active;
        else if (isSkipped && categoryStatusMap[categoryCode] !== CategoryStatus.Active)
            categoryStatusMap[categoryCode] = CategoryStatus.Skipped;
        else if (isAnswered && categoryStatusMap[categoryCode] === CategoryStatus.Pending)
            categoryStatusMap[categoryCode] = CategoryStatus.Completed;
    });

    return categoryStatusMap;
});

const showGuidelinesDialog = ref(false);
</script>

<template>
    <div v-if="createReviewPloc.state.institutionSite" class="hidden vp7:flex flex-col gap-8 p-8 w-full max-w-[340px] bg-white">
        <span class="c-tag typo-tag ">
            {{ createReviewPloc.state.institutionSite!.name }}
        </span>

        <div class="c-steps-vertical">
            <div class="c-steps-vertical__title">
                {{ snippets.get('create_review_vertical_progress_category1') }}
            </div>

            <ul class="c-steps-vertical__list">
                <CreateReviewFormProgressItem
                    :text="snippets.get('create_review_vertical_progress_category1_option1')"
                    :status="createReviewPloc.state.currentFixedStep === FixedSteps.InstitutionSite ? CategoryStatus.Active
                        : createReviewPloc.state.isInstitutionSiteConfirmed ? CategoryStatus.Completed : CategoryStatus.Pending"
                />
                <CreateReviewFormProgressItem
                    :text="snippets.get('create_review_vertical_progress_category1_option2')"
                    :status="createReviewPloc.state.currentFixedStep === FixedSteps.Guidelines ? CategoryStatus.Active
                        : createReviewPloc.state.areGuidelinesAccepted ? CategoryStatus.Completed : CategoryStatus.Pending"
                />
                <CreateReviewFormProgressItem
                    :text="snippets.get('create_review_vertical_progress_category1_option3')"
                    :status="createReviewPloc.state.currentFixedStep === FixedSteps.ReviewerRelationType ? CategoryStatus.Active
                        : createReviewPloc.state.isReviewerRelationTypeConfirmed ? CategoryStatus.Completed : CategoryStatus.Pending"
                />
            </ul>
        </div>

        <div class="c-steps-vertical">
            <div class="c-steps-vertical__title">
                {{ snippets.get('create_review_vertical_progress_category2') }}
            </div>

            <ul class="c-steps-vertical__list">
                <CreateReviewFormProgressItem
                    v-for="qpc in createReviewPloc.state.questions"
                    :key="qpc.categoryCode"
                    :text="qpc.categoryShortName"
                    :status="categoriesStatusses[qpc.categoryCode]"
                />
                <CreateReviewFormProgressItem
                    :text="snippets.get('create_review_vertical_progress_category2_option1')"
                    :status="createReviewPloc.state.currentFixedStep === FixedSteps.GeneralReview ? CategoryStatus.Active
                        : createReviewPloc.state.generalReview ? CategoryStatus.Completed : CategoryStatus.Pending"
                />
            </ul>
        </div>

        <div class="c-steps-vertical">
            <div class="c-steps-vertical__title">
                {{ snippets.get('create_review_vertical_progress_category3') }}
            </div>

            <ul class="c-steps-vertical__list">
                <CreateReviewFormProgressItem
                    :text="snippets.get('create_review_vertical_progress_category3_option1')"
                    :status="createReviewPloc.state.currentFixedStep === FixedSteps.PersonalInformation ? CategoryStatus.Active
                        : createReviewPloc.state.isReviewSubmitted ? CategoryStatus.Completed : CategoryStatus.Pending"
                />
            </ul>
        </div>

        <a class="c-link mt-auto mb-0 c-link--animated" href="#" target="_self" @click="showGuidelinesDialog = true">
            <span class="c-link__text">
                {{ snippets.get('create_review_accept_guidelines_card_cta') }}
            </span>
            <Icon class="c-link__icon" name="chevron-right" />
        </a>
    </div>

    <GuidelinesModal v-model="showGuidelinesDialog" />
</template>
