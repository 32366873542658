import type { ComparisonPloc } from '@core';

export function stateToUrl(comparisonPloc: ComparisonPloc) {
    const ids = comparisonPloc.state.addedItems.map(item => item.id.toString());

    const url = new URL(window.location.href.split('?')[0]);

    // Sectors & types to url
    ids.forEach((id) => {
        url.searchParams.append('orgId', id);
    });

    // Update the url in the browser
    window.history.replaceState({}, '', url);
}
