<script setup lang="ts">
import { defineEmits, defineProps, ref } from 'vue';
import Tree from 'primevue/tree';
import Icon from '@/components/Icon.vue';
import { useModelWrapper } from '@/utils/modelWrapper';

const props = defineProps({
    options: {
        required: true,
        default: () => [],
    },
    values: {
        default: {},
    },
});

const emit = defineEmits(['change', 'update:values']);

const values = useModelWrapper(props, emit, 'values');

const passtrough = ref({
    wrapper: { class: 'w-full' },
    container: { class: 'flex flex-col w-full gap-2' },
    node: { class: 'w-full' },
    content: ({ context }: { context: any }) => ({
        class: `flex w-full${context.expanded ? ' mb-2' : ''}`,
    }),
    label: { class: 'w-full ml-2 cursor-pointer' },
    subgroup: { class: 'pl-2 gap-2 flex flex-col' },
    toggler: ({ context }: { context: any }) => ({
        class: `flex items-center order-3 w-[18px] h-[18px] shrink-0 ml-2${context.leaf ? ' invisible' : ''}`,
    }),
    checkboxContainer: ({ _, props }: { _: any, props: any }) => {
        return {
            class: `mt-[.15em] c-checkbox${values.value[props.node.key]?.checked ? ' c-checkbox--checked' : ''}${values.value[props.node.key]?.partialChecked ? ' c-checkbox--checked' : ''}`,
        };
    },
    checkbox: { class: 'c-checkbox__input' },
});
</script>

<template>
    <Tree
        v-model:selectionKeys="values"
        class="w-full md:w-30rem"
        selection-mode="checkbox"
        :pt="passtrough"
        :value="props.options"
    >
        <template #togglericon="{ expanded }">
            <Icon :class="{ 'c-icon--flip': expanded }" name="chevron-down" />
        </template>
    </Tree>
</template>
