<script setup lang="ts">
import { onMounted, ref } from 'vue';
import type { Nullable } from '@core/common/types';
import CreateReviewComment from '@/components/create-review-comment/CreateReviewComment.vue';
import UpdateReviewComment from '@/components/create-review-comment/UpdateReviewComment.vue';

const selectedReviewId = ref<Nullable<number>>(null);
const selectedReviewCommentId = ref<Nullable<number>>(null);
const selectedToken = ref<Nullable<string>>(null);

onMounted(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const encodedToken = urlParams.get('token');
    if (!encodedToken)
        window.location.href = '/';

    const { reviewId, reviewCommentId, token } = JSON.parse(atob(encodedToken!));
    if (reviewId)
        selectedReviewId.value = Number.parseInt(reviewId);
    else if (reviewCommentId)
        selectedReviewCommentId.value = Number.parseInt(reviewCommentId);
    else
        window.location.href = '/';

    selectedToken.value = token;
});
</script>

<template>
    <CreateReviewComment v-if="selectedReviewId" v-model:review-id="selectedReviewId" v-model:token="selectedToken" />
    <UpdateReviewComment v-if="selectedReviewCommentId" v-model:review-comment-id="selectedReviewCommentId" v-model:token="selectedToken" />
</template>
