<script setup lang="ts">
import { defineModel, defineProps, ref } from 'vue';

import Checkbox from 'primevue/checkbox';
import SearchField from '@/components/SearchField.vue';
import MultiSelect from '@/components/MultiSelect.vue';

const props = defineProps({
    options: {
        type: Array,
        required: true,
    },
    optionValueProperty: {
        type: String,
        default: 'name',
    },
    allowCustomValue: {
        type: Boolean,
    },
    customValueLabel: {
        type: String,
    },
    customValuePlaceholder: {
        type: String,
    },
});

const emit = defineEmits(['customValueDebouncedUpdate']);

const values = defineModel<Array<string | number>>('values', { default: [] });
const hasCustomValue = defineModel<boolean>('hasCustomValue');
const customValue = defineModel<string>('customValue');

const customValueCheckboxPassthrough = ref({
    root: () => ({ class: hasCustomValue.value ? 'c-checkbox--checked' : '' }),
    input: () => ({ class: 'c-checkbox__input' }),
});
</script>

<template>
    <div class="multi-select-filter flex flex-col gap-2">
        <MultiSelect
            v-model="values"
            :options="props.options"
        />
        <div v-if="props.allowCustomValue" class="multi-select-filter__option flex items-center w-full">
            <Checkbox
                v-model="hasCustomValue"
                :binary="true"
                value="custom"
                input-id="custom"
                :pt="customValueCheckboxPassthrough"
                class="c-checkbox"
            />
            <label for="custom" class="ml-2 w-full cursor-pointer">
                {{ props.customValueLabel }}
            </label>
        </div>
        <SearchField
            v-if="hasCustomValue"
            v-model="customValue"
            :placeholder="props.customValuePlaceholder"
            :debounce-time="300"
            @debounced-update="emit('customValueDebouncedUpdate')"
        />
    </div>
</template>
