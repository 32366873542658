<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps({
    title: {
        type: String,
    },
    badge: {
        type: Number,
    },
});
const isOpen = ref(false);

function toggle() {
    isOpen.value = !isOpen.value;
}
</script>

<template>
    <div class="c-collapsible" :class="{ 'c-collapsible--open': isOpen }">
        <button class="c-collapsible__header typo-h6" @click="toggle">
            {{ props.title }} <span v-if="props.badge" class="c-collapsible__badge">{{ props.badge }}</span>
        </button>
        <div class="c-collapsible__body">
            <slot />
        </div>
    </div>
</template>
