<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';
import Icon from '@/components/Icon.vue';

defineProps({
    options: {
        type: Object,
        default: [],
    },
});

const emits = defineEmits(['click']);

const dropoutElement = ref(null);
const isOpen = ref(false);

const toggle = () => isOpen.value = !isOpen.value;
const close = () => isOpen.value = false;

function onClick(option) {
    emits('click', { option });
    close();
}

function onDocumentClick(e) {
    if (dropoutElement.value && !dropoutElement.value.contains(e.target))
        close();
}

onMounted(() => document.addEventListener('click', onDocumentClick));
onBeforeUnmount(() => document.removeEventListener('keydown', onDocumentClick));
</script>

<template>
    <div ref="dropoutElement" class="c-dropout">
        <button class="c-dropout__button" @click="toggle">
            <Icon class="c-dropout__icon" name="kebab" />
        </button>
        <div v-if="isOpen" class="c-dropout__options">
            <div v-for="option in options" class="c-dropout__option" @click="onClick(option)">
                <Icon :name="option.icon" />
                {{ option.label }}
            </div>
        </div>
    </div>
</template>
