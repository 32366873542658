<script setup lang="ts">
const props = defineProps({
    label: {
        type: String,
    },
    /*
    * When true, the switch will be checked
    * */
    checked: {
        type: Boolean,
        default: false,
    },
    /*
    * When true, the switch will be disabled and the user won't be able to interact with it
    * */
    disabled: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['toggle']);

function toggle() {
    if (!props.disabled)
        emit('toggle');
}
</script>

<template>
    <button
        class="c-switch" role="switch" :aria-label="props.label" :aria-checked="props.checked" :class="{
            'c-switch--checked': props.checked,
            'c-switch--disabled': props.disabled,
        }" @click="toggle"
    />
</template>
