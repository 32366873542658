<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { injectPloc } from '@core';
import { useReCaptcha } from 'vue-recaptcha-v3';
import CreateReviewTitle from '../components/CreateReviewTitle.vue';
import { CREATE_REVIEW_PLOC } from '@/utils';
import snippets from '@/utils/snippets';

import { Icon } from '@/components';
import Button from '@/components/Button.vue';
import { pushToMatomo } from '@/utils/matomo';

const createReviewPloc = injectPloc(CREATE_REVIEW_PLOC)!;

const { executeRecaptcha, recaptchaLoaded, instance } = useReCaptcha()!;

async function reCaptcha() {
    const token = await executeRecaptcha('login');
    if (token)
        createReviewPloc.state.reCaptchaToken = token;
}

const formRef = ref<HTMLInputElement | null>(null);

const isSubmitting = ref(false);

async function handleSubmit() {
    formRef.value!.reportValidity();
    if (formRef.value!.checkValidity()) {
        isSubmitting.value = true;
        await reCaptcha();
        await createReviewPloc.submitReview();
        createReviewPloc.goToNextFixedStep();
        pushToMatomo('formStep', createReviewPloc.state.institutionSite!.institutionType.name, snippets.get('create_review_submit'));
        isSubmitting.value = false;
    }
}

const isFormValid = computed(() => {
    return !isSubmitting.value
        && createReviewPloc.isValidEmail()
        && createReviewPloc.userAcceptConsent()
        && createReviewPloc.userAcceptDeclarationOfHonour()
        && createReviewPloc.userConfirmsUseOfPersonalInfo()
        && createReviewPloc.isValidPhone();
});

onMounted(async () => {
    await recaptchaLoaded();
    instance.value!.showBadge();
});
</script>

<template>
    <div class="bg-white flex flex-col items-center gap-8 p-8 vp7:rounded-[20px] self-start">
        <CreateReviewTitle
            :title="snippets.get('create_review_general_personalinfo_title')"
        />

        <div class="c-alert">
            <Icon class="c-alert--icon" name="info" />
            <div class="c-alert--content">
                <div class="c-alert--text" v-html="snippets.get('create_review_general_personalinfo_alert')" />
            </div>
        </div>

        <section class="p-8 bg-light-grey rounded-[20px] w-full">
            <form ref="formRef" class="flex flex-col gap-4" @submit.prevent="handleSubmit">
                <div class="flex flex-col vp9:flex-row gap-6 vp9:gap-2">
                    <div class="c-input-group">
                        <label for="firstname" class="c-label">Voornaam</label>
                        <input id="firstname" v-model="createReviewPloc.state.personalInfo.firstName" required autofocus class="c-input" type="text" name="firstname" :disabled="createReviewPloc.getIsUpdate()">
                    </div>
                    <div class="c-input-group">
                        <label for="lastname" class="c-label">Achternaam</label>
                        <input id="lastname" v-model="createReviewPloc.state.personalInfo.lastName" required class="c-input" type="text" name="lastname" :disabled="createReviewPloc.getIsUpdate()">
                    </div>
                </div>
                <div class="c-input-group">
                    <label for="email" class="c-label">E-mailadres</label>
                    <input id="email" v-model="createReviewPloc.state.personalInfo.mailAddress" required class="c-input" type="email" name="email" :disabled="createReviewPloc.getIsUpdate()">
                </div>
                <div class="c-input-group">
                    <label for="phone" class="c-label">
                        GSM of telefoonnummer
                        <span class="c-label__optional">{{ snippets.get('optional') }}</span>
                    </label>
                    <input id="phone" v-model="createReviewPloc.state.personalInfo.phoneNumber" class="c-input" type="tel" name="phone" maxlength="12" :disabled="createReviewPloc.getIsUpdate()">
                </div>

                <div class="c-checkbox-group">
                    <div class="c-checkbox-group__item">
                        <input id="consent" v-model="createReviewPloc.state.isUserConsent" class="c-checkbox-group__input" type="checkbox">
                        <label class="c-checkbox-group__label" for="consent">
                            <span class="c-checkbox-group__icon" />
                            <span>{{ snippets.get('create_review_general_personalinfo_consent') }}</span>
                            <Icon
                                v-tooltip.top="{
                                    value: `
                                        <p class='tooltip-title'>Door dit vakje aan te vinken bevestig je akkoord te zijn met de gebruiksvoorwaarden van het waarderingsplatform. Dit houdt in dat je:</p><br>
                                        <ul class='tooltip-list'>
                                            <li>Op de hoogte bent dat jouw waardering na goedkeuring door de redactie gepubliceerd zal worden op het waarderingsplatform.</li>
                                            <li>Je bewust bent van het doel waarvoor je gegevens en je waardering gebruikt worden.</li>
                                            <li>Weet dat je op elk moment je waardering en/of persoonsgegevens kan aanpassen of verwijderen.</li>
                                            <li>Op de hoogte bent van het feit dat jouw gegevens verwerkt en gebruikt worden door de redactie van het Waarderingsplatform volgens de principes van de privacyverklaring.</li>
                                        </ul>`,
                                    escape: true,
                                    class: 'c-tooltip c-tooltip--consent',
                                    hideDelay: 200,
                                }"
                                name="info"
                                class="c-checkbox-group__tooltip c-icon--decorated bg-black text-white shrink-0"
                            />
                        </label>
                    </div>
                </div>

                <div class="c-checkbox-group">
                    <div class="c-checkbox-group__item">
                        <input id="personalinfo" v-model="createReviewPloc.state.isUserConfirmOfPersonalInfo" class="c-checkbox-group__input" type="checkbox">
                        <label class="c-checkbox-group__label" for="personalinfo">
                            <span class="c-checkbox-group__icon" />
                            <span>{{ snippets.get('create_review_general_personalinfo_confirm_personal_info') }}</span>
                        </label>
                    </div>
                </div>

                <div class="c-checkbox-group">
                    <div class="c-checkbox-group__item">
                        <input id="declaration" v-model="createReviewPloc.state.isUserDeclarationOfHonour" class="c-checkbox-group__input" type="checkbox">
                        <label class="c-checkbox-group__label" for="declaration">
                            <span class="c-checkbox-group__icon" />
                            <span>{{ snippets.get('create_review_general_personalinfo_declaration') }}</span>
                        </label>
                    </div>
                </div>
            </form>
        </section>

        <div class="w-full">
            <div class="w-full vp9:w-auto flex gap-4 justify-between fixed bottom-0 left-0 right-0 px-4 py-6 bg-white border-t border-t-light-grey-2 vp7:relative vp7:p-0 z-50 vp7:border-none">
                <Button
                    class="c-button--primary c-button--outline min-w-auto"
                    :text="snippets.get('create_review_previous')"
                    @click="createReviewPloc.goToPreviousFixedStep();"
                />
                <button
                    type="button"
                    class="c-button c-button--secondary typo-button form-submit w-fit"
                    :disabled="!isFormValid"
                    :class="{ 'is-loading': isSubmitting }"
                    @click="handleSubmit()"
                >
                    {{ snippets.get('create_review_submit') }}
                </button>
            </div>
        </div>
    </div>
</template>
