/*
* Date formats are handled by date-fns (https://date-fns.org/) and are based on Unicode Technical Standard #35
* For a list of all possible symbols, refer to https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
* */

const dateFormats = {
    'review-date': 'd MMMM Y',
    'comment-date': 'd MMMM Y',
};

export default dateFormats;
