import { useMutation } from '@tanstack/vue-query';
import { reviewApi } from '../../../api';

import type { ReportReviewRequest } from '../../../api/review/requests/ReportReviewRequest';

function useCreateReviewReport() {
    const { mutate, isPending, isSuccess } = useMutation({
        mutationFn: (request: ReportReviewRequest) => reviewApi.reportReview(request),
        retry: false,
    });

    return {
        createReviewReport: mutate,
        isCreatingReviewReport: isPending,
        isSuccessCreatingReviewReport: isSuccess,
    };
}
export default useCreateReviewReport;
