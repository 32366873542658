<script setup lang="ts">
import { computed } from 'vue';
import { injectPloc } from '@core';
import snippetsUtil from '@/utils/snippets';
import { SEARCH_PLOC } from '@/utils';
import { stateToUrl } from '@/components/institution-site-search/utils/url-state';
import Collapsible from '@/components/Collapsible.vue';
import RadioButtonGroup from '@/components/RadioButtonGroup.vue';

const searchPloc = injectPloc(SEARCH_PLOC);

const selectedScoreFilterValue = computed({
    get() {
        return searchPloc.state.selectedReviewScore;
    },
    set(value) {
        searchPloc.selectReviewScores(value);
        stateToUrl(searchPloc);
    },
});
</script>

<template>
    <Collapsible :title="snippetsUtil.get('search_overall_score')" :badge="searchPloc.state.selectedReviewScore > 0 ? 1 : undefined">
        <RadioButtonGroup
            id="isSupportPerson"
            v-model="selectedScoreFilterValue"
            :options="
                [
                    {
                        value: 0,
                        displayValue: 'Alles',
                    },
                    {
                        value: 1,
                        displayValue: '1 of hoger',
                    },
                    {
                        value: 2,
                        displayValue: '2 of hoger',
                    },
                    {
                        value: 3,
                        displayValue: '3 of hoger',
                    },
                    {
                        value: 4,
                        displayValue: '4 of hoger',
                    },
                    {
                        value: 5,
                    },
                ]"
        />
    </Collapsible>
</template>
