<script setup lang="ts">
import { computed, ref } from 'vue';
import { type InstitutionSiteDetailReview, NotificationSeverity, injectPloc } from '@core';
import Tag from '../../Tag.vue';
import date from '@/utils/date';
import Rating from '@/components/Rating.vue';
import ExpandableText from '@/components/ExpandableText.vue';
import Dropout from '@/components/Dropout.vue';
import ReportReviewModal from '@/components/institution-site-detail/components/ReportReviewModal.vue';
import Comment from '@/components/institution-site-detail/components/Comment.vue';

import { NOTIFICATION_PLOC } from '@/utils';
import snippets from '@/utils/snippets';

const props = defineProps({
    review: {
        type: Object as () => InstitutionSiteDetailReview,
        required: true,
    },
});

const notificationPloc = injectPloc(NOTIFICATION_PLOC);

const formattedDate = computed(() => date.format(props.review.createDate, 'review-date'));

const isReporting = ref(false);
const isFlagging = ref(false);

const closeReportReviewModal = () => isReporting.value = false;
const closeFlagReviewModal = () => isFlagging.value = false;

function onDropoutSelect(e: any) {
    const { option } = e;

    isReporting.value = (option.value === 'report');
    isFlagging.value = (option.value === 'flag');
}

function onReportSuccess() {
    notificationPloc.notify({
        title: snippets.get('notification_review_report_success_title'),
        text: snippets.get('notification_review_report_success_description'),
        severity: NotificationSeverity.Success,
        duration: 5000,
    });
}

function onFlagSuccess() {
    notificationPloc.notify({
        title: snippets.get('notification_review_report_success_title'),
        text: snippets.get('notification_review_report_success_description'),
        severity: NotificationSeverity.Success,
        duration: 5000,
    });
}
</script>

<template>
    <div class="bg-light-grey p-6 rounded-[20px]">
        <div class="flex items-center justify-between mb-2">
            <span>{{ formattedDate }}</span>
            <Dropout
                :options="[
                    { value: 'flag', icon: 'flag', label: snippets.get('flag') },
                    { value: 'report', icon: 'report', label: snippets.get('report') },
                ]"
                @click="onDropoutSelect"
            />
        </div>
        <Rating :value="props.review.score" />
        <div class="mt-2">
            <ExpandableText :text="props.review.experienceText" />
        </div>
        <div v-if="props.review.answers.length" class="mt-8 flex flex-wrap gap-2">
            <template v-for="(answer, index) in props.review.answers">
                <Tag
                    v-if="answer.score !== null"
                    :key="index"
                    :text="`${answer.categoryName} ${answer.score}`"
                    class="typo-tag c-tag--small typo-small"
                />
            </template>
        </div>
        <Comment
            v-if="props.review.comment"
            :comment="props.review.comment"
            :author-name="props.review.institutionSiteName"
        />
    </div>
    <ReportReviewModal
        v-if="isReporting"
        :title="snippets.get('report')"
        text="Wil je graag een algemene melding maken over deze waardering? Wil je jouw waardering laten verwijderen? Dan kan dat hier!"
        remark-text-label="Waarom wil je deze waardering aanpassen of verwijderen?"
        submit-button-text="Melding maken"
        :review-id="props.review.reviewId"
        @close="closeReportReviewModal"
        @success="onReportSuccess"
    />
    <ReportReviewModal
        v-if="isFlagging"
        :title="snippets.get('flag_review')"
        remark-text-label="Licht nog even verder je reden toe"
        submit-button-text="Rapporteer"
        :include-report-reasons="true"
        :review-id="props.review.reviewId"
        @close="closeFlagReviewModal"
        @success="onFlagSuccess"
    />
</template>
