<script setup lang="ts">
import Button from '@/components/Button.vue';
import CreateReviewTitle from '@/components/create-review/components/CreateReviewTitle.vue';

defineProps({
    institutionSiteName: String,
});

const handleClose = function () {
    window.location.href = '/';
};
</script>

<template>
    <div class="bg-white flex flex-col items-center gap-8 p-8 vp7:rounded-[20px] self-start">
        <CreateReviewTitle
            title="Je reactie is verstuurd"
        />

        <div class="u-bg-box ">
            <div class="s-wysiwyg">
                <h5>Hartelijk bedankt!</h5>
                <p>Jouw reactie voor {{ institutionSiteName }} werd succesvol verstuurd. Onze redactie kijkt alles nog eens na en zal jou een mailtje sturen van zodra je reactie online is geplaatst.</p>
            </div>
        </div>

        <div class="w-full items-center flex flex-col">
            <Button
                class="c-button--secondary min-w-auto"
                text="Naar het waarderingsplatform"
                @click="handleClose"
            />
        </div>
    </div>
</template>
