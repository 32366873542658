import { useMutation } from '@tanstack/vue-query';
import { reviewApi } from '../../../api';

import type { PublishReviewRequest } from '../../../api/review/requests/PublishReviewRequest';

function usePublishReview() {
    const { mutate, isPending, isSuccess } = useMutation({
        mutationFn: (request: PublishReviewRequest) => reviewApi.publishReview(request),
        retry: false,
    });

    return {
        publishReview: mutate,
        isPublishingReview: isPending,
        isSuccessPublishReview: isSuccess,
    };
}
export default usePublishReview;
