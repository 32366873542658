<script setup lang="ts">
import type { SelectOption } from '@core/common/types';
import Checkbox from 'primevue/checkbox';
import { ref } from 'vue';

const props = defineProps({
    options: {
        type: Array<SelectOption>,
    },
});

const values = defineModel();

const checkboxPassthrough = ref({
    root: (options: any) => ({ class: values.value.includes(options.props.value) ? 'c-checkbox--checked' : '' }),
    input: () => ({ class: 'c-checkbox__input' }),
});
</script>

<template>
    <div class="flex flex-col gap-2">
        <div
            v-for="(option, index) in props.options"
            :key="index"
            class="flex items-center w-full"
        >
            <Checkbox
                v-model="values"
                :value="option.value"
                :input-id="option.value.toString()"
                :pt="checkboxPassthrough"
                class="mt-[.1em] c-checkbox"
            />
            <label :for="option.value.toString()" class="ml-2 cursor-pointer">
                {{ option.displayValue }}
            </label>
        </div>
    </div>
</template>
