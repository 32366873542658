<script setup lang="ts">
import snippets from '@/utils/snippets';

import Button from '@/components/Button.vue';

const handleClose = function () {
    window.location.href = '/';
};
</script>

<template>
    <div class="bg-white flex flex-col items-center gap-8 p-8 vp7:rounded-[20px] self-start">
        <div class="flex justify-center">
            <div class="flex flex-col gap-2 text-center w-full max-w-lg">
                <h2 class="typo-h4">
                    {{ snippets.get('contact_confirmation_title') }}
                </h2>
            </div>
        </div>

        <div class="u-bg-box ">
            <div class="s-wysiwyg">
                <h5>{{ snippets.get('contact_confirmation_description_title') }}</h5>
                <p>{{ snippets.get('contact_confirmation_description_text') }}</p>
            </div>
        </div>

        <div class="w-full">
            <Button
                class="c-button--secondary min-w-auto"
                :text="snippets.get('contact_confirmation_close')"
                @click="handleClose"
            />
        </div>
    </div>
</template>
