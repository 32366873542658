<script setup lang="ts">
import { computed } from 'vue';
import { injectPloc } from '@core';
import { CREATE_REVIEW_PLOC } from '@/utils';
import { Icon } from '@/components';
import { FixedSteps } from '@/components/create-review/types/FixedSteps';

const createReviewPloc = injectPloc(CREATE_REVIEW_PLOC)!;

const overallStep = computed(() => {
    if (createReviewPloc.state.currentFixedStep < FixedSteps.Questions)
        return createReviewPloc.state.currentFixedStep;

    return createReviewPloc.state.currentFixedStep - 1 + createReviewPloc.state.currentActiveQuestion - 1;
});

const getStepsPercentageCompleted = computed(() => {
    return overallStep.value > 0 && createReviewPloc.getTotalSteps() > 0
        ? overallStep.value / createReviewPloc.getTotalSteps() * 100
        : 0;
});

const handleStop = function () {
    window.location.href = '/';
};
</script>

<template>
    <div class="sticky block w-full top-0 left-0 right-0 bg-white z-50 vp7:hidden">
        <div class="layout bound w-full">
            <div class="c-form-progress">
                <button class="c-form-progress__button" @click="handleStop">
                    <Icon class="c-form-progress__button-icon" name="close" />
                </button>
                <progress id="progress" class="c-form-progress__progress" :value="getStepsPercentageCompleted" max="100">
                    {{ getStepsPercentageCompleted }}%
                </progress>

                <label v-if="createReviewPloc.state.questions.length > 0" class="c-form-progress__label" for="progress">
                    {{ overallStep }} van {{ createReviewPloc.getTotalSteps() }}
                </label>
            </div>
        </div>
    </div>
</template>
