<script setup lang="ts">
import { computed } from 'vue';
import { injectPloc } from '@core';
import Icon from '../../Icon.vue';
import { SEARCH_PLOC } from '@/utils';
import type { ViewMode } from '@/types/ViewMode';

const props = defineProps({
    options: {
        type: Array<ViewMode>,
        default: () => [
            { value: 'default', title: 'List view', icon: 'view-list' },
            { value: 'card', title: 'Card view', icon: 'view-grid' },
        ],
    },
});

const searchPloc = injectPloc(SEARCH_PLOC);

const currentValue = computed({
    get() {
        return searchPloc.state.viewMode;
    },
    set(value) {
        searchPloc.setViewMode(value);
    },
});
</script>

<template>
    <div class="c-view-toggle">
        <button
            v-for="option in props.options"
            :key="option.value"
            class="c-view-toggle__item"
            :class="{ 'c-view-toggle__item--active': currentValue === option.value }"
            @click="currentValue = option.value"
        >
            <Icon class="c-view-toggle__icon" :name="option.icon" />
        </button>
    </div>
</template>
