import { useMutation } from '@tanstack/vue-query';
import { contactApi } from '../../../api';

import type { CreateContactFormEntryRequest } from '../../../api/contact/requests/CreateContactFormEntryRequest';

function useCreateContactFormEntry() {
    const { mutate, isPending, isSuccess } = useMutation({
        mutationFn: (request: CreateContactFormEntryRequest) => contactApi.createContactFormEntry(request),
        retry: false,
    });

    return {
        createContactFormEntry: mutate,
        isCreatingContactFormEntry: isPending,
        isSuccessCreatingContactFormEntry: isSuccess,
    };
}
export default useCreateContactFormEntry;
