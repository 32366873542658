<script setup lang="ts">
import getReviewReportReasons from '@core/queries/review/getReviewReportReasons';
import MultiSelect from '@/components/MultiSelect.vue';
import snippets from '@/utils/snippets';

const { isPendingReviewReportReasons, reasons } = getReviewReportReasons();
const values = defineModel<number[]>({ default: [] });
</script>

<template>
    <div v-if="!isPendingReviewReportReasons" class="c-input-group">
        <label for="email" class="c-label">
            {{ snippets.get('review_reportReason_text') }}
        </label>
        <MultiSelect
            v-model="values"
            :options="reasons"
        />
    </div>
</template>
