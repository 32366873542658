<script setup lang="ts">
import { injectPloc } from '@core';
import Guidelines from '@/components/create-review/steps/Guidelines.vue';
import InstitutionSite from '@/components/create-review/steps/InstitutionSite.vue';
import ReviewQuestions from '@/components/create-review/steps/ReviewQuestions.vue';
import GeneralReview from '@/components/create-review/steps/GeneralReview.vue';
import CreateReviewHeader from '@/components/create-review/components/CreateReviewHeader.vue';
import CreateReviewFormProgressMobile from '@/components/create-review/components/CreateReviewFormProgressMobile.vue';
import CreateReviewFormProgress from '@/components/create-review/components/CreateReviewFormProgress.vue';
import { FixedSteps } from '@/components/create-review/types/FixedSteps';
import PersonalInfo from '@/components/create-review/steps/PersonalInfo.vue';
import Confirmation from '@/components/create-review/steps/Confirmation.vue';
import ReviewerRelationType from '@/components/create-review/steps/ReviewerRelationType.vue';

import { CREATE_REVIEW_PLOC } from '@/utils';

const props = defineProps({
    institutionsSiteComplaintUrl: {
        type: String,
    },
});

const createReviewPloc = injectPloc(CREATE_REVIEW_PLOC)!;
</script>

<template>
    <CreateReviewHeader />

    <main>
        <CreateReviewFormProgressMobile />
        <div class="flex pt-0 vp7:pt-[108px] pb-[100px] vp7:pb-0 min-h-screen">
            <CreateReviewFormProgress v-if="createReviewPloc.state.currentFixedStep >= FixedSteps.Questions" />
            <div class="flex justify-center w-full relative overflow-hidden vp9:u-bg-shape-tl vp9:u-bg-shape-tr" style="--shape-tl: url('@assets/shapes/shape-1.svg');--shape-tr: url('@assets/shapes/shape-2.svg')">
                <div class="layout bound w-full">
                    <!-- Step 1: Select institutionSite -->
                    <InstitutionSite v-if="createReviewPloc.state.currentFixedStep === FixedSteps.InstitutionSite" />

                    <!-- Step 2: Accept guidelines -->
                    <Guidelines v-else-if="createReviewPloc.state.currentFixedStep === FixedSteps.Guidelines" :complaint-url="props.institutionsSiteComplaintUrl" />

                    <!-- Step 3: Enter reviewer capacity -->
                    <ReviewerRelationType v-else-if="createReviewPloc.state.currentFixedStep === FixedSteps.ReviewerRelationType" />

                    <!-- Step 4: Enter score per question -->
                    <ReviewQuestions v-if="createReviewPloc.state.currentFixedStep === FixedSteps.Questions" />

                    <!-- Step 5: Give general review -->
                    <GeneralReview v-if="createReviewPloc.state.currentFixedStep === FixedSteps.GeneralReview" />

                    <!-- Step 6: Enter personal details -->
                    <PersonalInfo v-if="createReviewPloc.state.currentFixedStep === FixedSteps.PersonalInformation" />

                    <!-- Step 7: Confirm email -->

                    <!-- Step 8: Submission confirmation -->
                    <Confirmation v-if="createReviewPloc.state.isReviewSubmitted" />

                    <picture v-if="!createReviewPloc.state.institutionSite" class="c-image w-fit flex justify-self-end">
                        <img class="c-image__item ls-is-cached lazyloaded" src="@assets/images/peep-group.png" alt="">
                    </picture>
                    <picture v-else class="c-image w-fit flex justify-self-end absolute bottom-12 right-6 z-[-1]">
                        <img v-if="createReviewPloc.state.institutionSite?.sector.code === 'WVG_VAZG'" class="c-image__item ls-is-cached lazyloaded" src="@assets/images/peep-6.png" alt="">
                        <img v-else-if="createReviewPloc.state.institutionSite?.sector.code === 'WVG_JW'" class="c-image__item ls-is-cached lazyloaded" src="@assets/images/peep-9.png" alt="">
                        <img v-else-if="createReviewPloc.state.institutionSite?.sector.code === 'VAPH'" class="c-image__item ls-is-cached lazyloaded" src="@assets/images/peep-6.png" alt="">
                        <img v-else-if="createReviewPloc.state.institutionSite?.sector.code === 'WVG_KG'" class="c-image__item ls-is-cached lazyloaded" src="@assets/images/peep-16.svg" alt="">
                    </picture>
                </div>
            </div>
        </div>
    </main>
</template>
