import { type InjectionKey, inject, onMounted, onUnmounted, reactive } from 'vue';
import type { Ploc } from '../presentation';

export function injectPloc<T extends Ploc<any>>(key: InjectionKey<T>): T {
    const ploc = inject(key);
    if (!ploc)
        throw new Error('Ploc was not found');

    ploc.state = reactive(ploc.state);

    const stateSubscription = (state: any) => {
        Object.keys(state).forEach((stateProperty: string) => {
            ploc.state[stateProperty] = state[stateProperty];
        });
    };

    onMounted(() => ploc.subscribe(stateSubscription));

    onUnmounted(() => ploc.unsubscribe(stateSubscription));

    return ploc;
}
