import PrimeVue from 'primevue/config';
import type { App } from 'vue';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import { NotificationSeverity, dependenciesLocator } from '@core';
import { QueryCache, QueryClient, VueQueryPlugin } from '@tanstack/vue-query';
import { configureApi } from '@core/common/domain';
import {
    COMPARISON_PLOC,
    CREATE_REVIEW_PLOC,
    NOTIFICATION_PLOC,
    SEARCH_PLOC,
} from '@/utils';

export * as Components from './components/indexClient';

export function configureApp(app: App) {
    const notificationPloc = dependenciesLocator.provideNotificationPloc();

    const queryClient = new QueryClient({
        queryCache: new QueryCache({
            onError: (error) => {
                notificationPloc.notify({
                    title: 'API Error',
                    text: error.message,
                    severity: NotificationSeverity.Error,
                });
            },
        }),
    });

    app
        .use(VueReCaptcha, {
            siteKey: import.meta.env.VITE_RECAPTCHA_SITEKEY,
            loaderOptions: { autoHideBadge: true },
        })
        .use(VueQueryPlugin, { queryClient })
        .use(PrimeVue, { unstyled: true })
        .use(ToastService)
        .directive('tooltip', Tooltip);

    app.provide('queryClient', queryClient);

    app.provide(COMPARISON_PLOC, dependenciesLocator.provideComparisonPloc());
    app.provide(CREATE_REVIEW_PLOC, dependenciesLocator.provideCreateReviewPloc());
    app.provide(NOTIFICATION_PLOC, notificationPloc);
    app.provide(SEARCH_PLOC, dependenciesLocator.provideSearchPloc());

    configureApi(import.meta.env.VITE_BACKEND, {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, HEAD, POST, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Z-Key',
    });
}

export * as Vue from 'vue';
export { renderToString } from 'vue/server-renderer';
