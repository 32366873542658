<script setup lang="ts">
import { computed, ref } from 'vue';
import { injectPloc } from '@core';
import ViewToggle from '@/components/institution-site-search/components/ViewToggle.vue';
import Sort from '@/components/institution-site-search/components/Sort.vue';
import Results from '@/components/institution-site-search/components/Results.vue';
import Paginator from '@/components/Paginator.vue';
import Loader from '@/components/Loader.vue';
import SearchStatus from '@/components/institution-site-search/components/SearchStatus.vue';
import snippets from '@/utils/snippets';

import { SEARCH_PLOC } from '@/utils';
import { stateToUrl } from '@/components/institution-site-search/utils/url-state';

const searchPloc = injectPloc(SEARCH_PLOC);

const page = computed({
    get() {
        return searchPloc.state.page;
    },
    set(newPage: number) {
        searchPloc.setPage(newPage);
        stateToUrl(searchPloc);
        scrollTop();
    },
});

const sortProperties = [
    { label: 'Willekeurig', value: 'ran' },
    { label: 'Naam (a-z)', value: 'name' },
    { label: 'Naam (z-a)', value: '-name' },
    { label: 'Cijfer (hoog-laag)', value: '-overallScore' },
    { label: 'Cijfer (laag-hoog)', value: 'overallScore' },
    { label: 'Aantal waarderingen (hoog-laag)', value: '-reviewCount' },
    { label: 'Aantal waarderingen (laag-hoog)', value: 'reviewCount' },
];

const sortProperty = computed({
    get() {
        return sortProperties.find(property => property.value === searchPloc.state.sortBy);
    },
    set(option) {
        searchPloc.sortBy(option!.value);
        stateToUrl(searchPloc);
    },
});

const containerEl = ref<HTMLElement | null>(null);

function scrollTop() {
    containerEl.value?.scrollIntoView({ behavior: 'smooth' });
}
</script>

<template>
    <div ref="containerEl" class="flex flex-col col-span-2">
        <div v-if="searchPloc.state.isLoading" class="p-6 flex justify-center col-span-2">
            <Loader />
        </div>
        <template v-if="!searchPloc.state.isLoading">
            <div class="vp9:flex gap-6 mb-4 items-center hidden">
                <div class="grow">
                    <SearchStatus :suffix="searchPloc.state.pagination.totalAmount !== 1 ? 'organisaties' : 'organisatie'" />
                </div>
                <div class="c-sort flex gap-2">
                    <label id="sort">{{ snippets.get('sort_by') }}:</label>
                    <Sort
                        v-model="sortProperty"
                        :options="sortProperties"
                        label="Sorteren op"
                    />
                </div>
                <ViewToggle />
            </div>
            <Results />
            <Paginator
                v-model="page"
                :records-per-page="searchPloc.state.pagination.pageSize"
                :records-count="searchPloc.state.pagination.totalAmount"
            />
        </template>
    </div>
</template>
