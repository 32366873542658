<script setup lang="ts">
import { ref } from 'vue';
import { injectPloc } from '@core';
import Button from '@/components/Button.vue';
import SearchStatus from '@/components/institution-site-search/components/SearchStatus.vue';
import { COMPARISON_PLOC, SEARCH_PLOC } from '@/utils';
import { stateToUrl } from '@/components/institution-site-search/utils/url-state';
import scroll from '@/utils/scroll';
import { Icon } from '@/components';

const searchPloc = injectPloc(SEARCH_PLOC);
const comparisonPloc = injectPloc(COMPARISON_PLOC);

const isOpen = ref(false);

function toggle() {
    isOpen.value = !isOpen.value;

    if (isOpen.value) {
        document.body.classList.add('hide-header');
        scroll.disable();
        comparisonPloc.hidePanel();
        return;
    }

    document.body.classList.remove('hide-header');
    scroll.enable();
    comparisonPloc.showPanel();
}

function clearFilters() {
    searchPloc.clearFilters();
    stateToUrl(searchPloc);
}
</script>

<template>
    <div class="c-filter-stack" :class="{ 'c-filter-stack--open': isOpen }">
        <div class="c-filter-stack__header">
            <div class="grow">
                <SearchStatus :suffix="searchPloc.state.pagination.totalAmount !== 1 ? 'organisaties' : 'organisatie'" />
            </div>
            <Button
                text="Filters"
                icon="filter-list"
                class="c-button--small c-button--quaternary typo-small"
                :class="{ 'c-button--badged': searchPloc.getActiveFiltersCount() }"
                :data-badge="searchPloc.getActiveFiltersCount()"
                @click="toggle"
            />
        </div>
        <div class="c-filter-stack__body">
            <div class="c-filter-stack__title">
                Filters toevoegen
                <Icon name="close" class="w-6 h-6" @click="toggle" />
            </div>
            <div class="c-filter-stack__filters">
                <slot />
            </div>
            <div class="c-filter-stack__footer">
                <div v-if="searchPloc.getActiveFiltersCount()" class="text-center">
                    <SearchStatus :suffix="searchPloc.state.pagination.totalAmount !== 1 ? 'organisaties gevonden' : 'organisatie gevonden'" />
                </div>
                <Button
                    v-if="searchPloc.getActiveFiltersCount()"
                    class="c-button--secondary c-button--outline w-full"
                    text="Alle filters wissen"
                    @click="clearFilters"
                />
                <Button
                    class="c-button--secondary w-full"
                    text="Filters toepassen"
                    @click="toggle"
                />
            </div>
        </div>
    </div>
</template>
