<script setup lang="ts">
import { ref } from 'vue';
import Dropdown from 'primevue/dropdown';
import Icon from '../../Icon.vue';
import snippets from '@/utils/snippets';

const props = defineProps({
    label: {
        type: String,
    },
    options: {
        type: Array,
        default: () => [],
    },
});

const value = defineModel();

const passthrough = ref({
    input: {
        class: 'typo-strong',
    },
    panel: {
        class: 'bg-white rounded-[10px] py-8 drop-shadow-md mt-4 -translate-x-2/4',
    },
    wrapper: {
        class: 'c-sort__wrapper overflow-auto',
    },
    trigger: {
        class: 'flex items-center',
    },
    item: ({ context }) => ({
        class: [
            'px-8 py-2 cursor-pointer hover:bg-light-grey',
            {
                'bg-white': !context.focused && !context.selected,
                'ring-focus-blue ring-2 ring-inset': (context.focused && !context.selected) || (context.focused && context.selected),
                'bg-light-grey': !context.focused && context.selected,
            },
        ],
    }),
});
</script>

<template>
    <Dropdown
        v-model="value"
        :options="[{ label: props.label, items: props.options }]"
        :pt="passthrough"
        :placeholder="snippets.get('sort_by')"
        option-label="label"
        option-group-label="label"
        option-group-children="items"
        class="flex items-center gap-4 cursor-pointer"
        aria-labelledby="sort"
        scroll-height="fit-content"
    >
        <template #optiongroup="{ option }">
            <p class="typo-p-sm uppercase typo-strong px-8 py-2">
                {{ option.label }}
            </p>
        </template>
        <template #option="{ option }">
            <div v-if="option.value === value" class="flex items-center gap-2">
                <span class="block grow">{{ option.label }}</span>
                <span class="c-icon">
                    <Icon name="check" />
                </span>
            </div>
            <span v-else>{{ option.label }}</span>
        </template>
        <template #dropdownicon>
            <Icon name="chevron-down" />
        </template>
    </Dropdown>
</template>
