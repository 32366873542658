import { format as fnsFormat } from 'date-fns';
import { nlBE } from 'date-fns/locale';
import dateFormats from '../data/date-formats';

const date = {
    format(dateString: string, formatName: string) {
        return fnsFormat(new Date(dateString), formatName in dateFormats ? dateFormats[formatName as keyof typeof dateFormats] : 'MMMM Y', {
            locale: nlBE,
        });
    },
};

export default date;
