<script setup lang="ts">
import { computed, defineEmits, reactive } from 'vue';
import { injectPloc } from '@core';
import type { InstitutionSite } from '@core/features/search/domain/entities/InstitutionSite';
import getSuggestionList from '@core/queries/institutionSite/getSuggestionList';
import type { GetInstitutionSitesListRequest } from '@core/api/institutionSite/requests/GetInstitutionSiteListRequest';
import AutosuggestSearchField from '@/components/AutosuggestSearchField.vue';
import InstitutionSiteMedium from '@/components/items/InstitutionSiteMedium.vue';
import snippets from '@/utils/snippets';
import Button from '@/components/Button.vue';
import { COMPARISON_PLOC } from '@/utils';

const emit = defineEmits(['update', 'select']);
function onSelect(institutionSite: InstitutionSite) {
    emit('select', institutionSite);
}

function onUpdate(value: string) {
    emit('update', value);
}

const comparisonPloc = injectPloc(COMPARISON_PLOC);

const institutionType = computed(() => {
    if (comparisonPloc.state.isMakingNewComparison)
        return comparisonPloc.state.selectionType;

    return comparisonPloc.state.selectionType || comparisonPloc.state.institutionType;
});
const request = reactive<GetInstitutionSitesListRequest>({
    name: '',
    institutionType: institutionType?.value?.code,
    excludeUnapprovedInstitutionSites: true,
});

const { isPendingSuggestionList, institutionSites } = getSuggestionList(request);
</script>

<template>
    <AutosuggestSearchField
        v-model="request.name"
        :placeholder="snippets.get('search_institutionSite')"
        :is-pending="isPendingSuggestionList"
        :items="institutionSites"
        @update:model-value="onUpdate"
        @select="onSelect"
    >
        <template #item="{ item, hasFocus }">
            <div class="flex items-center justify-between pr-4" :class="{ 'bg-light-grey': hasFocus }">
                <InstitutionSiteMedium :institution-site="item" />
                <Button
                    v-if="comparisonPloc.state.isMakingNewComparison"
                    :disabled="!comparisonPloc.canBeSelected(item)"
                    class="c-button--tertiary"
                    text="Toevoegen"
                />
                <Button
                    v-if="comparisonPloc.state.isAddingItems"
                    :disabled="!comparisonPloc.canBeAdded(item)"
                    class="c-button--tertiary"
                    text="Toevoegen"
                />
            </div>
        </template>
        <template #empty>
            <div class="px-6 py-4">
                {{ snippets.get('no_results_text') }}
            </div>
        </template>
    </AutosuggestSearchField>
</template>
