<script setup lang="ts">
import { computed, ref } from 'vue';

import useGetInstitutionSiteDetailReviews from '@core/queries/institutionSite/getInstitutionSiteDetailReviews';
import type { InstitutionSiteDetail } from '@core';
import snippets from '@/utils/snippets';
import Button from '@/components/Button.vue';
import Error from '@/components/Error.vue';
import Review from '@/components/institution-site-detail/components/Review.vue';
import Sort from '@/components/institution-site-search/components/Sort.vue';
import Loader from '@/components/Loader.vue';

const props = defineProps({
    institutionSite: {
        type: Object as () => InstitutionSiteDetail,
        required: true,
    },
});

const pagedRequest = ref({
    institutionSiteId: props.institutionSite.institutionSiteId,
    page: 0,
    pageSize: 3,
    sortProperty: '-createDate',
});

const { reviewsPaged, isPendingGetReviews, isSuccessGetReviews } = useGetInstitutionSiteDetailReviews(pagedRequest.value);

const sortOptions = [
    { label: snippets.get('newest'), value: '-createDate' },
    { label: snippets.get('oldest'), value: 'createDate' },
];

// const sortValue = ref('new');
const sortModel = computed({
    get() {
        return sortOptions.find(property => property.value === pagedRequest.value.sortProperty);
    },
    set(option) {
        pagedRequest.value.sortProperty = option!.value;
    },
});

function loadNextPage() {
    pagedRequest.value.pageSize += 3;
}

const reviewGrammar = computed(() => {
    return props.institutionSite.totalReviewCount > 1 || props.institutionSite.totalReviewCount === 0 ? snippets.get('reviews') : snippets.get('review');
});
</script>

<template>
    <div v-if="isPendingGetReviews" class="p-6 flex justify-center">
        <Loader />
    </div>
    <div v-if="isSuccessGetReviews">
        <div v-if="!reviewsPaged!.data.length">
            <Error
                title="Geen waarderingen gevonden"
                text="Er werden (nog) geen waarderingen toegevoegd."
            />
        </div>
        <div v-else class="flex flex-col gap-4">
            <div class="flex gap-6 items-center">
                <div class="grow text-center vp7:text-left">
                    <strong>{{ props.institutionSite.totalReviewCount }}</strong> {{ reviewGrammar }}
                </div>
                <div class="c-sort hidden vp7:flex gap-2">
                    <label id="sort">{{ snippets.get('sort_by') }}:</label>
                    <Sort
                        v-model="sortModel"
                        :options="sortOptions"
                        :label="snippets.get('sort_by')"
                    />
                </div>
            </div>
            <div class="flex flex-col gap-4">
                <Review
                    v-for="review in reviewsPaged!.data"
                    :key="review.reviewId"
                    :review="review"
                    :institution-site-name="review.institutionSiteName"
                />
            </div>
            <div v-if="props.institutionSite.totalReviewCount > reviewsPaged!.data.length" class="flex justify-center mt-4">
                <Button
                    :text="snippets.get('load_more_reviews')"
                    class="c-button--outline c-button--secondary"
                    @click="loadNextPage"
                />
            </div>
        </div>
    </div>
</template>
