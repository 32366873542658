<script setup lang="ts">
import { computed, ref } from 'vue';

import { injectPloc } from '@core';
import CreateReviewTitle from '../components/CreateReviewTitle.vue';
import GuidelinesModal from '../components/GuidelinesModal.vue';
import { CREATE_REVIEW_PLOC } from '@/utils';
import snippets from '@/utils/snippets';
import { Icon } from '@/components';
import CreateReviewButtonNavigation from '@/components/create-review/components/CreateReviewButtonNavigation.vue';
import { pushToMatomo } from '@/utils/matomo';

const props = defineProps({
    complaintUrl: {
        type: String,
    },
});

const createReviewPloc = injectPloc(CREATE_REVIEW_PLOC)!;
const minRequiredAnswers = computed(() => createReviewPloc.state.questions.length - createReviewPloc.state.amountOfSkippableQuestions);
const showGuidelinesDialog = ref(false);

function acceptGuidelines() {
    createReviewPloc.acceptGuidelines();
    pushToMatomo('formStep', createReviewPloc.state.institutionSite!.institutionType.name, snippets.get('create_review_accept_guidelines_title'));
}

function handlePrevious() {
    const url = new URL(window.location.href.split('?')[0]);
    window.history.replaceState({}, '', url);
    createReviewPloc.reset();
}
</script>

<template>
    <div class="bg-white flex flex-col items-center gap-8 p-8 vp7:rounded-[20px] self-start">
        <CreateReviewTitle
            :title="snippets.get('create_review_accept_guidelines_title')"
        />

        <article class="c-card bg-light-grey w-full">
            <div class="c-card__content">
                <h3 class="typo-h6">
                    {{ snippets.get('create_review_accept_guidelines_card_title') }}
                </h3>
                <p>
                    <span
                        v-html="snippets.get('create_review_accept_guidelines_card_description', { minRequiredAnswers: minRequiredAnswers.toString(),
                                                                                                   totalQuestions: createReviewPloc.state.questions.length.toString() })"
                    />
                </p>
            </div>
            <div class="c-card__links">
                <a class="c-link  c-card__link c-link--animated" href="#" target="_self" @click="showGuidelinesDialog = true">
                    <span class="c-link__text">
                        {{ snippets.get('create_review_accept_guidelines_card_cta') }}
                    </span>
                    <Icon class="c-link__icon" name="chevron-right" />
                </a>
            </div>
        </article>

        <div class="c-alert">
            <Icon class="c-alert--icon" name="info" />
            <div class="c-alert--content">
                <div class="c-alert--text" v-html="snippets.get('create_review_accept_guidelines_alert', { complaintUrl: props.complaintUrl! })" />
            </div>
        </div>

        <div class="c-alert">
            <Icon class="c-alert--icon" name="info" />
            <div class="c-alert--content">
                <div class="c-alert--text" v-html="snippets.get('create_review_accept_guidelines_alert_helpline', { helpLineUrl: 'https://www.1712.be/nl' })" />
            </div>
        </div>

        <div class="w-full">
            <CreateReviewButtonNavigation
                :next-caption="snippets.get('create_review_accept_guidelines_next')"
                @next-clicked="acceptGuidelines"
                @previous-clicked="handlePrevious"
            />
        </div>
    </div>

    <GuidelinesModal v-model="showGuidelinesDialog" />
</template>
