<script setup lang="ts">
import { injectPloc } from '@core';
import CreateReviewTitle from '../components/CreateReviewTitle.vue';
import { CREATE_REVIEW_PLOC } from '@/utils';
import snippets from '@/utils/snippets';

import CreateReviewButtonNavigation from '@/components/create-review/components/CreateReviewButtonNavigation.vue';
import { pushToMatomo } from '@/utils/matomo';

const createReviewPloc = injectPloc(CREATE_REVIEW_PLOC)!;

function handleNext() {
    pushToMatomo('formStep', createReviewPloc.state.institutionSite!.institutionType.name, snippets.get('create_review_general_review_title'));
}
</script>

<template>
    <div class="bg-white flex flex-col items-center gap-8 p-8 vp7:rounded-[20px] self-start">
        <CreateReviewTitle
            :title="snippets.get('create_review_general_review_title')"
            :subtitle="snippets.get('create_review_general_review_subtitle')"
        />

        <div class="u-bg-box">
            <div class="flex pb-8 gap-2 items-start">
                <span class="c-label c-label--large block w-full">
                    {{ snippets.get('create_review_general_average_score_title') }}
                </span>
                <span class="c-tag typo-tag bg-white">
                    {{ createReviewPloc.getAverageUserScore() }}/5
                </span>
            </div>
            <div class="c-input-group">
                <textarea id="general-review" v-model="createReviewPloc.state.generalReview" autofocus class="c-input" name="general-review" rows="6" :placeholder="snippets.get('create_review_general_review_placeholder')" />
            </div>
        </div>

        <div class="w-full">
            <CreateReviewButtonNavigation
                :is-next-disabled="!createReviewPloc.state.generalReview"
                @next-clicked="handleNext"
            />
        </div>
    </div>
</template>
