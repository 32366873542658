<script setup lang="ts">
import CreateReviewTitle from '../components/CreateReviewTitle.vue';
import snippets from '@/utils/snippets';

import Button from '@/components/Button.vue';

const handleClose = function () {
    window.location.href = '/';
};
</script>

<template>
    <div class="bg-white flex flex-col items-center gap-8 p-8 vp7:rounded-[20px] self-start">
        <CreateReviewTitle
            :title="snippets.get('create_review_confirmation_title')"
        />

        <div class="u-bg-box ">
            <div class="s-wysiwyg">
                <h5>{{ snippets.get('create_review_confirmation_description_title') }}</h5>
                <p>{{ snippets.get('create_review_confirmation_description_text') }}</p>
            </div>
        </div>

        <div class="w-full">
            <Button
                class="c-button--secondary min-w-auto"
                :text="snippets.get('create_review_confirmation_close')"
                @click="handleClose"
            />
        </div>
    </div>
</template>
