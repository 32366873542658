<script setup lang="ts">
import snippets from 'public/src/utils/snippets';
import { injectPloc } from '@core';
import { Icon } from '@/components';

import { CREATE_REVIEW_PLOC } from '@/utils';
import { pushToMatomo } from '@/utils/matomo';
import { fixedStepsDisplayMap } from '@/components/create-review/types/FixedSteps';

const createReviewPloc = injectPloc(CREATE_REVIEW_PLOC)!;

const handleStop = function () {
    pushToMatomo(
        'waarderingStop',
        createReviewPloc.state.institutionSite?.institutionType?.name ?? '',
        fixedStepsDisplayMap[createReviewPloc.state.currentFixedStep],
    );
    window.location.href = '/';
};
</script>

<template>
    <header class="fixed top-0 left-0 right-0 w-full z-50 hidden vp7:flex">
        <nav class="w-full layout bound-x justify-between items-center  bg-white border-b border-b-light-grey-2 transition-[border-radius] duration-300">
            <div class="b-site-header__content py-8 vp7:flex gap-12 w-full items-center justify-between">
                <h2 class="sr-only">
                    {{ snippets.get('create_review_header_navigation') }}
                </h2>
                <div class="flex gap-4 items-center justify-between">
                    <div class="flex items-center">
                        <a class="c-logo max-w-[237px]" href="/" aria-label="logo">
                            <picture class="c-image">
                                <img class="c-image__item ls-is-cached lazyloaded" src="@/assets/images/logo-Waarderingsplatform.svg" alt="logo Waarderingsplatform">
                            </picture>
                        </a>
                    </div>
                </div>
                <div v-if="!createReviewPloc.state.isReviewSubmitted" id="site-header-navigation" class="flex vp7:flex-row flex-col gap-6 p-6 vp7:p-[unset]">
                    <button class="c-button typo-button c-button--outline c-button--alert c-button--primary" @click="handleStop">
                        {{ snippets.get('create_review_header_stop') }}
                        <Icon class="c-button__icon" name="close" />
                    </button>
                </div>
            </div>
        </nav>
    </header>
</template>
