import type { SearchPloc } from '@core';

export function urlToState(searchPloc: SearchPloc) {
    const params = new URLSearchParams(window.location.search);

    const stateParams = {
        ...params.has('sort') && { sortBy: params.get('sort') },
        ...params.has('search') && { searchString: params.get('search') },
        ...params.has('city') && { customCityString: params.get('city') },
        ...params.has('city') && { isSearchingByCityString: true },
        ...params.has('provinces') && { selectedProvinces: params.getAll('provinces') },
        ...params.has('page') && { page: Number.parseInt(params.get('page')!) },
        ...params.has('score') && { selectedReviewScore: params.get('score') },
        ...params.has('types') && { selectedTypes: params.getAll('types') },
        sectorFilterParams: params.getAll('types'),
    };

    searchPloc.setParameters(stateParams);
}

export function stateToUrl(searchPloc: SearchPloc) {
    const url = new URL(window.location.href.split('?')[0]);

    // Sorting to url
    if (searchPloc.state.sortBy)
        url.searchParams.set('sort', searchPloc.state.sortBy);

    // Custom city search to url
    if (searchPloc.state.isSearchingByCityString && searchPloc.state.customCityString)
        url.searchParams.set('city', searchPloc.state.customCityString);

    // Search string to url
    if (searchPloc.state.searchString)
        url.searchParams.set('search', searchPloc.state.searchString);

    // Page to url
    if (searchPloc.state.page && searchPloc.state.page !== 1)
        url.searchParams.set('page', searchPloc.state.page.toString());

    // Provinces to url
    searchPloc.state.selectedProvinces.forEach((province: string) => {
        url.searchParams.append('provinces', province);
    });

    // Sectors & types to url
    for (const property in searchPloc.state.selectedSectorFilters) {
        if (searchPloc.state.selectedSectorFilters[property].checked)
            url.searchParams.append('types', property);
    }

    // Scores to url
    if (searchPloc.state.selectedReviewScore > 0)
        url.searchParams.append('score', searchPloc.state.selectedReviewScore.toString());

    // Update the url in the browser
    window.history.replaceState({}, '', url);
}
