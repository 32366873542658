<script setup lang="ts">
import { computed, ref, toRaw } from 'vue';

import type { ComparisonItem } from '@core';
import { injectPloc } from '@core';
import type { InstitutionSite } from '@core/features/search/domain/entities/InstitutionSite';
import snippets from '@/utils/snippets';

import SelectionList from '@/components/SelectionList.vue';
import Note from '@/components/Note.vue';
import InstitutionSiteMedium from '@/components/items/InstitutionSiteMedium.vue';

import { COMPARISON_PLOC } from '@/utils';
import InstitutionSiteSuggestionSearch from '@/components/comparison/components/InstitutionSiteSuggestionSearch.vue';
import Overlay from '@/components/Overlay.vue';
import Window from '@/components/Window.vue';
import Button from '@/components/Button.vue';

const props = defineProps({
    /**
     * The minimum amount of items
     */
    min: {
        type: Number,
        default: 1,
    },
    /**
     * The maximum amount of items
     */
    max: {
        type: Number,
        default: 3,
    },
    /*
    * The text of the confirm button
    * */
    confirmButtonText: {
        type: String,
        default: snippets.get('confirm'),
    },
    /*
    * The title of the modal
    * */
    title: {
        type: String,
    },
});

const emits = defineEmits(['close', 'cancel', 'confirm']);
const comparisonPloc = injectPloc(COMPARISON_PLOC);

const isOpen = ref(true);

function close() {
    isOpen.value = false;
    emits('close');
}

function confirm() {
    emits('confirm');
    close();
}

function cancel() {
    emits('cancel');
    close();
}

function select(item: ComparisonItem) {
    comparisonPloc.select(toRaw(item));
}

const institutionType = computed(() => {
    if (comparisonPloc.state.isMakingNewComparison)
        return comparisonPloc.state.selectionType?.name;

    return comparisonPloc.state.selectionType?.name || comparisonPloc.state.institutionType?.name;
});

const title = computed(() => props.title + (institutionType.value ? ` (${institutionType.value})` : ''));

const note = computed(() => {
    return snippets.get('same_type_comparison_note') + (institutionType.value ? ` (${institutionType.value})` : '');
});

const canSubmit = computed(() => {
    return comparisonPloc.state.selectedItems.length >= props.min && comparisonPloc.state.selectedItems.length <= props.max;
});
</script>

<template>
    <Overlay v-if="isOpen">
        <Window
            :has-scrollable-content="false"
            :title="title"
            @action="e => close()"
        >
            <div class="flex flex-col gap-4">
                <InstitutionSiteSuggestionSearch v-if="comparisonPloc.state.selectedItems.length < props.max" @select="select" />
                <SelectionList :items="comparisonPloc.state.selectedItems" @remove="item => comparisonPloc.deselect(item)">
                    <template #default="{ item }">
                        <InstitutionSiteMedium :institution-site="item as InstitutionSite" />
                    </template>
                </SelectionList>
                <Note :text="note" />
            </div>
            <template #footer>
                <div class="flex flex-col gap-2 vp6:gap-4 vp6:flex-row w-100">
                    <Button
                        :text="snippets.get('close')"
                        type="button"
                        class="c-button--secondary c-button--outline"
                        @click="cancel"
                    />
                    <Button
                        v-tooltip.top="
                            {
                                class: 'c-tooltip',
                                value: snippets.get('tooltip_start_comparison', { minValue: min.toString(), organisationSuffix: props.min > 1 ? 'organisaties' : 'organisatie' }),
                                hideDelay: 300,
                                disabled: canSubmit,
                            }"
                        type="button"
                        :disabled="!canSubmit"
                        :text="props.confirmButtonText"
                        class="c-button--secondary"
                        @click="confirm"
                    />
                </div>
            </template>
        </window>
    </Overlay>
</template>
