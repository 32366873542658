<script setup lang="ts">
import { ref, watch } from 'vue';
import useCreateReviewReport from '@core/mutations/public/review/createReviewReport';
import Confirm from '@/components/Confirm.vue';
import ReportReasonsMultiSelect from '@/components/institution-site-detail/components/ReportReasonsMultiSelect.vue';

const props = defineProps({
    title: {
        type: String,
    },
    text: {
        type: String,
    },
    remarkTextLabel: {
        type: String,
        default: 'Opmerkingen',
    },
    submitButtonText: {
        type: String,
        default: 'Verstuur',
    },
    reviewId: {
        type: Number,
        required: true,
    },
    includeReportReasons: {
        type: Boolean,
    },
});

const emits = defineEmits(['close', 'success']);

function close() {
    emits('close');
}

const formRef = ref<HTMLFormElement | null>(null);
const email = ref('');
const comment = ref('');
const reasons = ref([]);

const { createReviewReport, isCreatingReviewReport, isSuccessCreatingReviewReport } = useCreateReviewReport();

watch(isCreatingReviewReport, () => {
    if (isSuccessCreatingReviewReport.value) {
        emits('success');
        close();
    }
});

function handleSubmit() {
    formRef.value!.reportValidity();
    if (formRef.value!.checkValidity()) {
        createReviewReport({
            reviewId: props.reviewId,
            mailAddress: email.value,
            remarkText: comment.value,
            reasons: reasons.value,
        });
    }
}
</script>

<template>
    <Confirm
        :title="props.title"
        :confirm-button-text="props.submitButtonText"
        @confirm="handleSubmit"
        @cancel="close"
    >
        <div v-if="props.text" class="mb-4">
            {{ props.text }}
        </div>
        <form ref="formRef" class="flex flex-col gap-6" @submit.prevent="handleSubmit">
            <ReportReasonsMultiSelect
                v-if="props.includeReportReasons"
                v-model="reasons"
            />
            <div class="c-input-group">
                <label for="email" class="c-label">E-mailadres</label>
                <input id="email" v-model="email" required autofocus class="c-input" type="email" name="email">
            </div>
            <div class="c-input-group">
                <label for="comment" class="c-label">
                    {{ props.remarkTextLabel }}
                </label>
                <textarea id="comment" v-model="comment" class="c-input" name="comment" rows="4" placeholder="Typ hier extra uitleg" />
            </div>
        </form>
    </Confirm>
</template>
