<script setup lang="ts">
import Button from '@/components/Button.vue';
import Icon from '@/components/Icon.vue';
import snippets from '@/utils/snippets';

const props = defineProps({
    /*
    * When true, the floating panel is open
    * */
    isOpen: {
        type: Boolean,
        default: false,
    },
    /*
    * When true, the floating panel will hide itself from the viewport (not visible by the user)
    * */
    isHidden: {
        type: Boolean,
        default: false,
    },
    /*
    * The title of the floating panel
    * */
    title: {
        type: String,
        required: true,
    },
    /*
    * Badge is used to display a number in the form of a badge
    * */
    badge: {
        type: Number,
    },
    /*
    * The text of the main confirm button
    * */
    buttonText: {
        type: String,
        default: snippets.get('confirm'),
    },
    /*
    * When true, the main confirm button will be disabled (and unclickable)
    * */
    buttonDisabled: {
        type: Boolean,
        default: false,
    },
});

const emits = defineEmits(['open', 'close', 'confirm']);

function toggle() {
    if (props.isOpen) {
        emits('close');
        return;
    }
    emits('open');
}

function confirm() {
    emits('confirm');
}
</script>

<template>
    <div class="c-floating-panel" :class="{ 'c-floating-panel--open': props.isOpen, 'c-floating-panel--hidden': props.isHidden }">
        <div class="c-floating-panel__header" @click="toggle">
            <span v-if="props.badge" class="c-floating-panel__badge typo-strong">
                {{ props.badge }}
            </span>
            <span class="c-floating-panel__title">
                {{ props.title }}
            </span>
            <Icon
                :class="{ 'c-icon--flip': props.isOpen }"
                name="chevron-up"
                class="c-floating-panel__toggler"
            />
        </div>
        <div class="c-floating-panel__body">
            <slot />
        </div>
        <div class="c-floating-panel__footer">
            <Button
                :disabled="props.buttonDisabled"
                :text="props.buttonText"
                class="c-button--secondary" @click="confirm"
            />
        </div>
    </div>
</template>
