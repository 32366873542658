<!-- eslint-disable prefer-promise-reject-errors -->
<script setup lang="ts">
import { NotificationSeverity, injectPloc } from '@core';
import { NOTIFICATION_PLOC } from '@/utils';
import snippets from '@/utils/snippets';

const props = defineProps({
    /*
    * The text of the button
    * */
    displayName: {
        type: String,
    },
});

const notificationPloc = injectPloc(NOTIFICATION_PLOC);

function copyLinkToClipboard(e: Event) {
    e.stopPropagation();
    e.preventDefault();
    const url = window.location.href;
    const textArea = document.createElement('textarea');
    textArea.value = url;
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    notificationPloc.notify({
        title: snippets.get('notification_share_button_title'),
        text: snippets.get('notification_share_button_text'),
        severity: NotificationSeverity.Success,
        duration: 5000,
    });

    return new Promise<void>((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
    });
}
</script>

<template>
    <a class="c-button typo-button c-button--outline c-button--primary" @click="copyLinkToClipboard($event)">
        {{ props.displayName }}
    </a>
</template>
